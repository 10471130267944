/**
 * Tests if this distances map contains all the same values
 * Returns true if yes, false otherwise
 *
 * @param { map } maxDistances
 */
export function allSame(maxDistances) {
  if (!maxDistances) {
    return true;
  }

  // Handle the case when we load an example and then add a
  // new data source block. This avoids setting the simple
  // search block to disabled.
  return Object.values(maxDistances)
    .every((distance) => Object.values(maxDistances)
      .every((distance2) => (distance === distance2)));
}

/**
 * Given an map of distances (e.g., {"ground1-space1": 500, "ground2-space1": 500}),
 * exctract the common distance value and return it.
 *
 * If the distances are not all the same, return null
 *
 * @param { map } maxDistances
 */
export function extractValueFromMaxDistances(maxDistances) {
  if (!allSame(maxDistances)) {
    return '';
  }

  if (!maxDistances) {
    return '';
  }

  if (maxDistances === {}) {
    return '=';
  }

  return Object.values(maxDistances)[0];
}

/**
 * Infer the default values based on a set of predefined rules.
 * 1. if it's space-space, default is null
 * 2. if it's ground-ground, default is 0
 * 3. if it's ground-space, default is
 *  if other values are the same, use that value
 *  else, use 500
 * @param the distance type (gg, ss, gs) for ground-ground, space-space, space-ground
 */
function inferDefaultValue(type, existingValue, distanceMap) {
  if (type === 'gg') {
    if (!existingValue) {
      return 0;
    }
    return existingValue;
  }
  if (type === 'ss') {
    if (!existingValue) {
      return null;
    }
    return existingValue;
  }
  if (type === 'gs' || type === 'ge' || type === 'es' || type === 'ee' || type === 'ag' || type === 'as' || type === 'ae' || type === 'aa') {
    if (!existingValue) {
      let commonValue = extractValueFromMaxDistances(distanceMap);
      if (!commonValue) {
        commonValue = 500;
      }
      return commonValue;
    }
    return existingValue;
  }
  throw new Error(`Don't know how to infer defaults from distance type ${type}`);
}

/**
 * This function takes a single distance value and
 * expands it into the max_distances block.
 * E.g.,
 * query with 2 ground and 1 space block and
 * distance value 500 turns into
 *
 * max_distances {
 *  "ground1-ground2": 500,
 *  "ground1-space1": 500,
 *  "ground2-space1": 500
 * }
 *
*/
export function buildMaxDistancesFromValue(distanceValue, query) {
  // if query is not provided, just return empty
  if (!query.ground && !query.space && !query.events && !query.adhoc) {
    return {};
  }

  // build arrays of ground1, ground2, space1, space2, etc.
  let ground = [];
  let space = [];
  let event = [];
  let adhoc = [];
  if (query.ground) {
    ground = query.ground.map((g, index) => `ground${index + 1}`);
  }
  if (query.space) {
    space = query.space.map((s, index) => `space${index + 1}`);
  }
  if (query.events) {
    event = query.events.map((s, index) => `events${index + 1}`);
  }
  if (query.adhoc) {
    adhoc = query.adhoc.map((s, index) => `adhoc${index + 1}`);
  }

  // build the combinations of entries, e.g., [[ground1, space1], [ground2, space1]]
  const cartesian = (...a) => a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));

  // function that converts e.g., ([ground1, space1], 900) into {"ground1-space1": 900}
  // TODO: write a spec for buildDistances to ensure values are never returned with 'undefined'
  function buildDistances(type, arr1, arr2) {
    return Object.entries(cartesian(arr1, arr2)
      .filter((combo) => combo[0] !== combo[1])
      .sort((combo1, combo2) => combo1.sort() - combo2.sort())
      .reduce((map, combo) => {

        const key = `${combo[0]}-${combo[1]}`;
        const existingValue = query.max_distances[key];
        if (!distanceValue) {
          // there are a set of rules to use for setting the initial default value
          const newvalue = inferDefaultValue(type, existingValue, map);
          // TODO: understand what this line is really doing... search for "javascript map reduce to update a map key value"
          const distance = (map[key] = newvalue, map);
          return distance;
        }
        const newvalue = distanceValue;
        const distance = (map[key] = newvalue, map);
        return distance;
      }, {}));
  }

  // build distances for each type of combination
  const groundSpace = buildDistances('gs', ground, space);
  const groundGround = buildDistances('gg', ground, ground);
  const spaceSpace = buildDistances('ss', space, space);
  const groundEvent = buildDistances('ge', ground, event);
  const eventEvent = buildDistances('ee', event, event);
  const eventSpace = buildDistances('es', event, space);
  const adhocGround = buildDistances('ag', adhoc, ground);
  const adhocSpace = buildDistances('as', adhoc, space);
  const adhocEvent = buildDistances('ae', adhoc, event);
  const adhocAdhoc = buildDistances('aa', adhoc, adhoc);

  // combine them all
  const maxDistances = new Map([
    ...groundSpace,
    ...groundGround,
    ...spaceSpace,
    ...groundEvent,
    ...eventEvent,
    ...eventSpace,
    ...adhocGround,
    ...adhocSpace,
    ...adhocEvent,
    ...adhocAdhoc]);

  // and return an object from the map entries
  return Object.fromEntries(maxDistances);
}

/**
 * Takes one of the max_distance array entry (e.g., ["ground1-space1", 500]) and
 * returns an array of ["ground1", "space1"];
 *
 * @param {array} distance
 */
export function extractKeyPair(distance) {
  if (distance.length === 0) {
    return [];
  }
  // there are actually 2 entries - 1 for the key (e.g., "ground1-space1"), and 1 for the value
  if (distance.length === 2) {
    return distance[0].split('-', 2);
  }

  throw new Error('Expecting 0 or 1 distance entries, not many');
}

export function rebuildMaxDistancesFromQuery(query) {
  const simpleValue = extractValueFromMaxDistances(query.max_distances);
  if (simpleValue) {
    return buildMaxDistancesFromValue(simpleValue, query);
  }
  return buildMaxDistancesFromValue(null, query);
}

export function fromQueryIntoMaxDistances(query) {
  const maxDistances = {};
  for (const i in query.max_distances) {
    if (Object.prototype.hasOwnProperty.call(query.max_distances, i)) {
      maxDistances[i] = query.max_distances[i];
    }
  }
  return maxDistances;
}

export function fromMaxDistancesIntoQuery(maxDistances, query) {
  query.max_distances = {};
  for (const i in maxDistances) {
    if (Object.prototype.hasOwnProperty.call(maxDistances, i)){
      query.max_distances[i] = maxDistances[i];
    }
  }
  return query;
}
