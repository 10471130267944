import React from 'react';
import { Steps } from 'intro.js-react';

function Tutorial({ steps, sessionName, stepsEnabled, setStepsEnabled }) {
  return (
    <Steps
      enabled={(stepsEnabled === 'undefined') ? true : stepsEnabled}
      steps={steps}
      initialStep={0}
      options={{
        showStepNumbers: false,
        showProgress: true,
        scrollToElement: false,
        keyboardNavigation: true,
        exitOnEsc: true,
        hidePrev: true,
        hideNext: true,
        overlayOpacity: 0.4,
        nextLabel: "Next &#8594;",
        prevLabel: "&#8592; Back",
      }}
      onStart={function() {
        setTimeout(function() {
          let elem = document.getElementById('tutorial-checkbox');
          if (elem) {
            elem.onclick = function() {
              if (elem.checked) {
                localStorage.setItem(`${sessionName}`, "true");
              } else {
                localStorage.setItem(`${sessionName}`, "");
              }
            };
          }
        }, 750);
      }}
      onExit={() => {
        setStepsEnabled(false);
      }}
      onBeforeChange={function() {
        setTimeout(function() {
          let elem = document.getElementById('tutorial-checkbox');
          if (elem) {
            elem.checked = localStorage.getItem(`${sessionName}`);
          }
        }, 500);
      }}
      onAfterChange={function() {
        setTimeout(function() {
          let elem = document.getElementById('tutorial-checkbox');
          if (elem) {
            elem.onclick = function() {
              if (elem.checked) {
                localStorage.setItem(`${sessionName}`, "true");
              } else {
                localStorage.setItem(`${sessionName}`, "");
              }
            };
          }
        }, 750);
      }}
    />
  );
}

export default Tutorial;