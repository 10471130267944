/* eslint-disable react-hooks/exhaustive-deps */
// Importing Components from node_modules
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

import MetadataFilters from "./MetadataFilters";
import RemoveCriteria from "./RemoveCriteria";
import ResetCriteria from "./ResetCriteria";
import SelectHemisphere from "./selectors/SelectHemisphere";
import SelectInstrumentTypes from "./selectors/SelectInstrumentTypes";
import SelectPlatforms from "./selectors/SelectPlatforms";
import SelectPrograms from "./selectors/SelectPrograms";
import { useDropdownSearchContext } from "./context/stateManager";
import CustomLatLon from "./CustomLatLon";

export function sumTotalNumberOfCriterialBlocks(state) {
  const events = state?.queryObject?.events ? state.queryObject.events.length : 0;
  const ground = state?.queryObject?.ground ? state.queryObject.ground.length : 0;
  const space = state?.queryObject?.space ? state.queryObject.space.length : 0;
  const adhoc = state?.queryObject?.adhoc ? state.queryObject.adhoc.length : 0;
  return events + ground + space + adhoc;
}

export default function CriteriaBlocksTable() {
  const { state } = useDropdownSearchContext();

  return (
    <table className="table table-compact criteria-table">
      <thead className="thead-dark">
        <tr>
          <th className="name-col">Name</th>
          <th className="programs-col">Programs</th>
          <th className="platforms-col">Platforms</th>
          <th className="instrument-col">Footprint Of</th>
          <th className="hemisphere-col">
            Hemisphere&nbsp;
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>
                  Use to limit conjunctions to when the geographic location of the satellite is in the specified hemisphere
                </Tooltip>
              }>
              <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
            </OverlayTrigger>
          </th>
          <th className="metadata-col">Metadata Filters</th>
          <th className="button-col">&nbsp;</th>
          <th className="button-col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {
          state.allDataSources.length > 0 ?
            <>
              {
                state.queryObject.ground
                && state.queryObject.ground.map((ground, index) => {
                  return (
                    <CriteriaRow key={`ground-${index}`} type="ground" index={index} sourceType={["ground"]} />
                  );
                })
              }
              {
                state.queryObject.space
                && state.queryObject.space.map((space, index) => (
                  <CriteriaRow key={`space-${index}`} type="space" index={index} sourceType={["leo", "heo", "lunar"]} />
                ))
              }
              {
                state.queryObject.events
                && state.queryObject.events.map((event, index) => (
                  <CriteriaRow key={`events-${index}`} type="events" index={index} sourceType={["event_list"]} />
                ))
              }
              {
                state.queryObject.adhoc
                && state.queryObject.adhoc.map((event, index) => (
                  <AdhocRow key={`adhoc-${index}`} type="adhoc" index={index} sourceType={["adhoc"]} />
                ))
              }
            </> : null
        }
      </tbody>
    </table>
  );
}

const CriteriaRow = ({ type, index, sourceType }) => {
  return (
    <>
      <tr>
        <td className="align-top name-col">
          {type === "ground" ? "Ground" : type === "space" ? "Space" : "Event"}
          {" "}
          {(index + 1)}
        </td>
        <td className="align-top programs-col"><SelectPrograms type={type} index={index} /></td>
        <td className="align-top platforms-col"><SelectPlatforms type={type} index={index} /></td>
        <td className="align-top instrument-col"><SelectInstrumentTypes type={type} index={index} /></td>
        <td className="align-top hemisphere-col"><SelectHemisphere type={type} index={index} /></td>
        <td className="metadata-col"><MetadataFilters filterBySourceType={sourceType} type={type} index={index} /></td>
        <td className="button-col"><ResetCriteria type={type} index={index} /></td>
        <td className="button-col"><RemoveCriteria type={type} index={index} /></td>
      </tr>
    </>
  );
};


const AdhocRow = ({ type, index }) => {
  return (
    <>
      <tr>
        <td className="align-top name-col">
          {"Custom "}
          {(index + 1)}
        </td>
        <td className="align-top programs-col" colSpan="5"><CustomLatLon type={type} index={index} /></td>
        <td className="button-col"><ResetCriteria type={type} index={index} /></td>
        <td className="button-col"><RemoveCriteria type={type} index={index} /></td>
      </tr>
    </>
  );
};
