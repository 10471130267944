// get the deployment type from environment var
let deployment_type = process.env.REACT_APP_DEPLOYMENT_TYPE;

// set as development if it's not defined
if (deployment_type !== 'staging' && deployment_type !== 'production') {
  deployment_type = 'development';
}

// export for use elsewhere in the app
export { deployment_type };
