import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { CheckPicker } from "rsuite";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft, faWarning } from "@fortawesome/pro-regular-svg-icons";
import { isSafari } from "react-device-detect";

import { useDropdownSearchContext } from "./context/stateManager";
import { Error, Success } from "../components/Toasts";
import { changePage } from "./ResultsTable";

const $ = require("jquery");

const getPageDirection = (wantedRow) => {
    let table = $("#conjunction-table").DataTable();
    let tableIndexes = table.rows().indexes();
    let pageInfo = table.page.info();

    if (tableIndexes.indexOf(wantedRow) >= pageInfo.end) {
        return "next";
    } else if (tableIndexes.indexOf(wantedRow) < pageInfo.start) {
        return "previous";
    }
};

const DeepLinks = ({ deepLinksActive, setDeepLinksActive }) => {
    const picker = useRef();
    const indexRef = useRef();
    const [value, setValue] = useState([]);
    const valueRef = useRef();
    valueRef.current = value;
    const [deepLinkWindows, setDeepLinkWindows] = useState({
        "swarm-aurora-conjunction-finder": {
            "url": "https://swarm-aurora.com/conjunctionFinder?disable_tutorial=true",
            "window": null,
            "selectpicker_grouping": ["Swarm-Aurora", "Conjunction Finder"],
        },
        "swarm-aurora-keogram-browser": {
            "url": "https://swarm-aurora.com/keogramBrowser?disable_tutorial=true",
            "window": null,
            "selectpicker_grouping": ["Swarm-Aurora", "Keogram Browser"],
        },
        "aig-data-portal": {
            "url": "https://data-portal.phys.ucalgary.ca",
            "window": null,
            "selectpicker_grouping": ["UCalgary", "Data Portal"],
        },
    });
    const windowTimers = {};
    const [currentRow, setCurrentRow] = useState(-1);
    const currentRowRef = useRef();
    currentRowRef.current = currentRow;
    const windowParams = `width=${parseInt(window.innerWidth * 0.90)},height=${parseInt(window.innerHeight * 0.90)}`;
    // debugging deep links interval timers
    const debug_deepLinks = false;  // change to true to enable console.log messages for the deep links debugging

    const options = [
        { value: "swarm-aurora-conjunction-finder", label: "Conjunction Finder", site: "Swarm-Aurora" },
        { value: "swarm-aurora-keogram-browser", label: "Keogram Browser", site: "Swarm-Aurora" },
        { value: "aig-data-portal", label: "Data Portal", site: "UCalgary" },
    ];

    const { state } = useDropdownSearchContext();

    useEffect(() => {
        for (const val of valueRef.current) {
            if (deepLinkWindows[val].window === null) {
                startWindow(val);
            }
        }
    }, [value]);

    // update deep link buttons to enabled/disabled state
    useEffect(() => {
        if (deepLinksActive !== null) {
            // update datatable rows
            $("#conjunction-table").DataTable().rows().every(function (row_index) {
                let data = this.data();
                let deep_links_html = `<a id="datatable_index_${row_index}" class="btn btn-sm btn-outline-dark ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${row_index}">${deepLinksActive ? "Load <i class='fa-solid fa-upload' />" : "Enable first"}</a>`;
                data["deep_links"] = deep_links_html;
                this.data(data);
            });
        }
    }, [deepLinksActive]);

    useEffect(() => {
        // We have to set a session variable to track the interval between
        // page routes. If the user navigates away from the page, and then
        // back to the conjunction search, we retrieve the old time, kill
        // it, and then create a new timer. This prevents multiple timers
        // from stacking on top of each other. Issue #56 https://git.phys.ucalgary.ca/aurorax/main-webapp/-/issues/56
        //
        // We also can't use the same interval timer because if we navigate
        // away from the page and then come back, the callback in the timer
        // is no longer valid -- the state is gone, and deep links will not
        // work. This is why we dispose and create a new timer each time we
        // load this component.
        let deepLinksTimer = sessionStorage.getItem("deepLinksTimer");
        if (deepLinksTimer) {
            if (debug_deepLinks) {
                console.log("Deep links timer cleared", deepLinksTimer);
            }
            clearInterval(deepLinksTimer);
        }
        deepLinksTimer = setInterval(() => {
            if (debug_deepLinks) {
                console.log("running in interval");
            }
            let val = document.getElementById("deepLinksIndex");
            if (val === null) {
                return;
            } else {
                if (Number(val.innerHTML) !== currentRowRef.current) {
                    setCurrentRow(Number(val.innerHTML));
                    currentRowRef.current = Number(document.getElementById("deepLinksIndex").innerHTML);
                }
            }
        }, 250);
        sessionStorage.setItem("deepLinksTimer", deepLinksTimer);
        if (debug_deepLinks) {
            console.log("Deep links timer created", deepLinksTimer);
        }
    }, []);

    useEffect(() => {
        if (currentRow > -1) {
            updateTable();

            let newRow = document.getElementById(`datatable_index_${currentRow}`);

            if (newRow === null) {
                // the element isn't on this page - find out which way to move the page
                let pageDirection = getPageDirection(currentRow);
                changePage(pageDirection);

            }

            broadcastEvent(currentRow);
        }
    }, [currentRow]);

    useEffect(() => {
        if (state.request.data !== null) {
            let val = document.getElementById("deepLinksIndex");
            if (val !== null) {
                val.innerHTML = "-1";
            }
        }
    }, [state.request.data]);

    const broadcastEvent = (eventIndex) => {
        try {
            const thisEvent = state.request.data[eventIndex];

            const deep_link_message = {
                "aurorax_deep_links_event": {
                    "dt_created_utc": DateTime.utc(),
                    "conjunction_search": {
                        "dt_event_start_utc": DateTime.fromISO(thisEvent["start"]),
                        "dt_event_end_utc": DateTime.fromISO(thisEvent["end"]),
                        "instruments":
                            state.request.data[eventIndex].data_sources.map(ds => ({
                                "program": ds["program"],
                                "platform": ds["platform"],
                                "instrument_type": ds["instrument_type"],
                                "id_string": ds["program"] + ", " + ds["platform"] + ", " + ds["instrument_type"],
                            })),
                        "additional_values": {
                            "aurorax_conjunction_search_event": thisEvent,
                            "aurorax_conjunction_search_query": state.request.search_request.query,
                        },
                    },
                },
            };

            let numWindowsOpen = 0;

            // send message to each URL, display toast
            for (const name in deepLinkWindows) {
                // post message to each URL that's enabled
                if (deepLinkWindows[name]["window"] !== null) {
                    numWindowsOpen++;

                    // get window
                    let w = deepLinkWindows[name]["window"];

                    // post message
                    w.postMessage(JSON.stringify(deep_link_message), deepLinkWindows[name]["url"]);
                }
            }

            if (numWindowsOpen > 0) {
                // show toast
                Success("Sent deep link command to load event");
            }
        } catch {
            Error("Error sending deep links message, try reloading the page and starting again");
        }

    };

    const startWindow = (name) => {
        // Issue #62
        // There appears to be a bug with Firefox and React 16+ which requires
        // a timeout to open and render the popout window. When the timeout is added,
        // React no longer throws an error "Should not already by working". Without
        // the timeout, Deep Links is not functional in Firefox.
        // this also fixes Deep Links in Chrome when launched from VS Code in debug
        // mode. Prior to the timeout, Deep Links did not launch the popout window.
        // More details found here: https://github.com/facebook/react/issues/17355
        setTimeout(() => {
            if (deepLinkWindows[name] && deepLinkWindows[name].window === null) {
                const w = window.open(deepLinkWindows[name].url, "_blank", windowParams);
                picker.current.close();

                if (valueRef.current.length > 0) {
                    setDeepLinksActive(true);
                }

                setDeepLinkWindows({
                    ...deepLinkWindows,
                    [name]: {
                        ...deepLinkWindows[name],
                        window: w,
                    },
                });

                // interval that checks if the window was closed
                windowTimers[name] = setInterval(win => {
                    if (win.closed) {
                        clearInterval(windowTimers[name]);

                        let newVal = [...valueRef.current.filter(val => val !== name)];
                        setValue(newVal);

                        setDeepLinkWindows(dLW => (
                            {
                                ...dLW,
                                [name]: {
                                    ...dLW[name],
                                    window: null,
                                },
                            }
                        ));

                        if (newVal.length === 0) {
                            disableDeepLinks();
                        }
                    }
                }, 250, w);
            }
        }, 50);
    };

    const updateTable = () => {
        $("#conjunction-table").DataTable().rows().every(function (row_index) {
            let data = this.data();
            let deep_links_html;
            if (row_index !== currentRow) {
                deep_links_html = `<a id="datatable_index_${row_index}" class="btn btn-sm btn-outline-dark ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${row_index}">${deepLinksActive ? "Load <i class='fa-solid fa-upload' />" : "Enable first"}</a>`;
            } else {
                deep_links_html = `<a id="datatable_index_${row_index}" class="btn btn-sm dlButton ${deepLinksActive ? "btn-success" : "btn-outline-dark disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${row_index}">${deepLinksActive ? "Loaded <i class='fa-solid fa-check' />" : "Enable first"}</a>`;
            }

            data["deep_links"] = deep_links_html;
            this.data(data);
        });
    };

    const moveEvent = (mode) => {
        let currIndexes = $("#conjunction-table").DataTable().rows().indexes();

        if (mode === "previous") {
            if (currentRow == -1 || currIndexes.length == 0 || (currIndexes.length > 0 && currentRow == currIndexes[0])) {
                // do nothing, already at beginning of table
                return;
            } else {
                // ok to move back, find index of current selected value
                let indexNow = -1;
                for (let i in currIndexes) {
                    if (currIndexes[i] == currentRow) {
                        indexNow = i;
                        break;
                    }
                }

                // update selected value
                if (indexNow > -1) {
                    // set new index
                    let newSelection = currIndexes[indexNow - 1];

                    // update properties of current selected index
                    let rowData = $("#conjunction-table").DataTable().row(currentRow).data();
                    rowData["deep_links"] = `<a id="datatable_index_${currentRow}" class="btn btn-sm btn-outline-dark ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${currentRow}">${deepLinksActive ? "Load <i class='fa-solid fa-upload' />" : "Enable first"}</a>`;
                    $("#conjunction-table").DataTable().row(currentRow).data(rowData);

                    // update properties of new selected index
                    rowData = $("#conjunction-table").DataTable().row(newSelection).data();
                    rowData["deep_links"] = `<a id="datatable_index_${newSelection}" class="btn btn-sm btn-success ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${currentRow}">Loaded <i class='fa-solid fa-check' /></a>`;
                    $("#conjunction-table").DataTable().row(newSelection).data(rowData).draw(false);

                    // update selection variable
                    document.getElementById("deepLinksIndex").innerHTML = `${newSelection}`;

                } else {
                    Error("Error moving to the previous event to load.");
                }

            }
        } else if (mode === "next") {
            if (currIndexes.length == 0 || (currIndexes.length > 0 && currentRow == currIndexes[currIndexes.length - 1])) {
                // do nothing, already at end
                return;
            } else {
                // find index of current selected value
                let indexNow = -1;
                let newSelection;
                if (currentRow == -1) {
                    indexNow = 0;
                    newSelection = currIndexes[indexNow];
                } else {
                    // find current index
                    for (let i = 0; i < currIndexes.length; i++) {
                        if (currIndexes[i] == currentRow) {
                            indexNow = i;
                            break;
                        }
                    }

                    // set new index
                    newSelection = currIndexes[indexNow + 1];
                }

                // update selected value
                if (indexNow > -1) {
                    // update properties of current selected index
                    if (currentRow != -1) {
                        let rowData = $("#conjunction-table").DataTable().row(currentRow).data();
                        rowData["deep_links"] = `<a id="datatable_index_${currentRow}" class="btn btn-sm btn-outline-dark ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${currentRow}">${deepLinksActive ? "Load <i class='fa-solid fa-upload' />" : "Enable first"}</a>`;
                        $("#conjunction-table").DataTable().row(currentRow).data(rowData);
                    }

                    // update properties of new selected index
                    let rowData = $("#conjunction-table").DataTable().row(newSelection).data();
                    rowData["deep_links"] = `<a id="datatable_index_${newSelection}" class="btn btn-sm btn-success ${deepLinksActive ? "" : "disabled"}" onclick="document.getElementById('deepLinksIndex').innerHTML=${currentRow}">Loaded <i class='fa-solid fa-check' /></a>`;
                    $("#conjunction-table").DataTable().row(newSelection).data(rowData).draw(false);

                    // update selection variable
                    document.getElementById("deepLinksIndex").innerHTML = `${newSelection}`;
                } else {
                    // couldn't find the current selected index to move it to the previous one
                    Error("Error moving forward to the next event to load.");
                }
            }
        }
    };

    const handleChange = (selected) => {
        for (let val of valueRef.current) {
            if (!selected.includes(val)) {
                // this option was unchecked, close the window and update its state
                deepLinkWindows[val].window.close();
                deepLinkWindows[val].window = null;

            }
        }

        setValue(selected);
    };

    const disableDeepLinks = () => {
        setDeepLinksActive(false);
        setCurrentRow(-1);
    };

    if (!isSafari) {
        return (
            <>
                <div className="mb-1">
                    <div id="deepLinksIndex" hidden ref={indexRef}>-1</div>
                    <CheckPicker
                        className="mr-1"
                        placeholder={!isSafari ? "Enable" : "Not supported"}
                        size="sm"
                        data={options}
                        value={valueRef.current}
                        onSelect={handleChange}
                        cleanable={false}
                        groupBy="site"
                        ref={picker}
                        placement={"autoVerticalStart"}
                        style={{ width: 150 }}
                        menuStyle={{ zIndex: 1050 }}
                        searchable={false}
                        menuMaxHeight={window.innerHeight * 0.5 - 120}
                        disabled={isSafari ? true : false}
                    />
                    <Button
                        size="sm"
                        disabled={!deepLinksActive}
                        variant="outline-dark"
                        className="mr-1"
                        onClick={() => moveEvent("previous")}>
                        <FontAwesomeIcon icon={faCaretLeft} fixedWidth />Previous
                    </Button>
                    <Button
                        size="sm"
                        disabled={!deepLinksActive}
                        variant="outline-dark"
                        onClick={() => moveEvent("next")}>
                        Next
                        <FontAwesomeIcon icon={faCaretRight} fixedWidth />
                    </Button>
                </div>
            </>
        );
    } else {
        // is safari, we don't support Deep Links for that browser
        return (
            <>
                <div id="deepLinksIndex" hidden ref={indexRef}>-1</div>
                <div style={{ paddingTop: '0.3em', wordWrap: 'break-word' }}>
                    <FontAwesomeIcon icon={faWarning} fixedWidth />
                    <span style={{ paddingLeft: '0.25em' }}>Deep links not supported, please use Chrome<br />or Firefox</span>
                </div>
            </>
        );
    }
};

export default DeepLinks;