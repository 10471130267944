// Importing Components from node_modules
import React from 'react';
import { Button } from 'react-bootstrap';

import { example1, example2, example3, example4, example5, example6, example7, example8 } from '../components/exampleQueries';
import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';

export default function Examples({ setStepsEnabled }) {
  const { dispatch } = useDropdownSearchContext();

  /*
    For some reason:
      onChange(value.filter(x => allValue.includes(x)))
    in MultiSelect.jsx causes examples that require additional grounds/spaces criteria than what
    is currently visible on screen to partially load in the example. It seems like the DOM hasn't
    properly updated to include the appropriate number of ground/space criteria before updating
    the query/selector values. So the second set query on a delay is meant to resolve this issue.
  */
  const setExample1 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 1, exampleQuery: JSON.parse(JSON.stringify(example1)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 1, exampleQuery: JSON.parse(JSON.stringify(example1)) },
      });
    }, 200);
  };
  const setExample2 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 2, exampleQuery: JSON.parse(JSON.stringify(example2)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 2, exampleQuery: JSON.parse(JSON.stringify(example2)) },
      });
    }, 200);
  };
  const setExample3 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 3, exampleQuery: JSON.parse(JSON.stringify(example3)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 3, exampleQuery: JSON.parse(JSON.stringify(example3)) },
      });
    }, 200);
  };
  const setExample4 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 4, exampleQuery: JSON.parse(JSON.stringify(example4)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 4, exampleQuery: JSON.parse(JSON.stringify(example4)) },
      });
    }, 200);
  };
  const setExample5 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 5, exampleQuery: JSON.parse(JSON.stringify(example5)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 5, exampleQuery: JSON.parse(JSON.stringify(example5)) },
      });
    }, 200);
  };
  const setExample6 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 6, exampleQuery: JSON.parse(JSON.stringify(example6)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 6, exampleQuery: JSON.parse(JSON.stringify(example6)) },
      });
    }, 200);
  };
  const setExample7 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 7, exampleQuery: JSON.parse(JSON.stringify(example7)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 7, exampleQuery: JSON.parse(JSON.stringify(example7)) },
      });
    }, 200);
  };
  const setExample8 = () => {
    dispatch({
      type: dropdownSearchActions.LOAD_EXAMPLE,
      payload: { exampleNumber: 8, exampleQuery: JSON.parse(JSON.stringify(example8)) },
    });
    setTimeout(() => {
      dispatch({
        type: dropdownSearchActions.LOAD_EXAMPLE,
        payload: { exampleNumber: 8, exampleQuery: JSON.parse(JSON.stringify(example8)) },
      });
    }, 200);
  };

  return (
    <>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample1}>1</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample2}>2</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample3}>3</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample4}>4</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample5}>5</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample6}>6</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample7}>7</Button></div>
      <div className="mr-1 example-btn"><Button variant="outline-dark" size="sm" onClick={setExample8}>8</Button></div>
      <Button
        className="tutorial-btn"
        variant="outline-dark" size="sm"
        onClick={() => {
          setStepsEnabled(true);
        }}
      >
        Tutorial
      </Button>
    </>
  );
}
