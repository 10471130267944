import React, { useEffect } from "react";
import axios from "axios";
import { validate } from "uuid";

import { conjunctionSearchResponse, dataSourcesUrl } from "../../../../utils/auroraXapi";
import { deployment_type } from "../../../../deployment";
import { rebuildMaxDistancesFromQuery } from "../distanceUtilities";
import { Error, Warn, Success } from "../../components/Toasts";
import {
  example1,
  example2,
  example3,
  example4,
  example5,
  example6,
  example7,
  example8,
} from '../../components/exampleQueries';

const DropdownSearchContext = React.createContext();
DropdownSearchContext.displayName = "Dropdown Search Page Context";
const DEBUG = false; // set this to true to get console logs of dispatch actions (there's another check for production lower down)

export const useDropdownSearchContext = () => React.useContext(DropdownSearchContext);

export const DropdownSeachProvider = ({ requestID, children }) => {
  const [state, dispatch] = React.useReducer(dropdownSearchReducer, initialState);

  // fetch all data sources on first render
  useEffect(() => {
    axios.get(
      dataSourcesUrl,
      {
        params: {
          format: "full_record",
        },
      },
    )
      .then((resp) => resp.data)
      .then((data) => data.filter(ds => (ds['source_type'] !== 'not_applicable')))
      .then((data) => {
        // if a UUID is provided:
        //  - if it is a valid UUID:
        if (requestID && validate(requestID)) {
          //      - check that it exists in the API
          axios
            .get(`${conjunctionSearchResponse}/${requestID}`)
            .then(requestInfo => requestInfo.data)
            .then(info => {
              // - if it exists:
              //   - fetch the request info
              //   - fetch the request data
              axios.get(`${conjunctionSearchResponse}/${requestID}/data`)
                .then(dataResponse => {
                  if (dataResponse.data.error) {
                    // outdated file, results were deleted
                    Warn("The search result you requested is no longer available. Press 'Search' to run the query for an updated result.", 9000);

                    // set up the query so the user can hit search again if they want to
                    // note - we have to do it twice to get all the criteria block rows to show properly
                    dispatch({
                      type: dropdownSearchActions.SET_QUERY,
                      payload: {
                        queryID: requestID,
                        query: { ...info.search_request.query },
                      },
                    });
                    dispatch({
                      type: dropdownSearchActions.SET_QUERY,
                      payload: {
                        queryID: requestID,
                        query: { ...info.search_request.query },
                      },
                    });

                  } else {
                    // data = dataResponse.data.result
                    // this is a good place to dispatch the initial state
                    // note - we have to do it twice to get all the criteria block rows to show properly
                    dispatch({
                      type: dropdownSearchActions.SET_QUERY,
                      payload: {
                        queryID: requestID,
                        query: { ...info.search_request.query },
                      },
                    });
                    dispatch({
                      type: dropdownSearchActions.INITIALIZE_FROM_ID,
                      payload: {
                        queryID: requestID,
                        queryObject: {
                          ...info.search_request.query,
                        },
                        request: {
                          ...info,
                          data: [...dataResponse.data.result],
                        },
                      },
                    });

                    Success(`Loaded conjunction search with request ID ${requestID}`);
                  }
                })
                .catch(() => {
                  // outdated file, results were deleted
                  Error("The search result you requested is no longer available. Press 'Search' to run the query for an updated result.", 9000);
                });
            })
            .catch(err => {
              //      - if it doesn't exist:
              //          - show a Toast Error and continue setting up page as if there is no requestID
              if (err.response && err.response.status && err.response.status === 404) {
                Error("Invalid request ID. Please check that you are using the correct request ID in UUID format.");
              }
            });
        } else if (requestID) {
          //  - if it isn"t a valid UUID:
          //      - throw a Toast Error and continue setting up the page as if there is no requestID
          Error("Invalid request ID.");
        }
        dispatch({
          type: dropdownSearchActions.SET_DATA_SOURCES,
          payload: {
            sources: data,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: dropdownSearchActions.ERROR,
        });
        Error("There was an error fetching data from our server. Please try again.");
      });
  }, []);

  return (
    <DropdownSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </DropdownSearchContext.Provider>
  );
};

export const dropdownSearchActions = {
  INITIALIZE_FROM_ID: "INITIALIZE_FROM_ID",
  SET_DATA_SOURCES: "SET_DATA_SOURCES",
  SET_DATETIME: "SET_DATETIME",
  SET_ADVANCED_DISTANCES: "SET_ADVANCED_DISTANCES",
  SET_CONJUNCTION_TYPES: "SET_CONJUNCTION_TYPES",
  TOGGLE_SUBMINUTE_PRECISION: "TOGGLE_SUBMINUTE_PRECISION",
  ADD_CRITERIA_BLOCK: "ADD_CRITERIA_BLOCK",
  SET_CRITERIA_PROGRAMS: "SET_CRITERIA_PROGRAMS",
  SET_CRITERIA_PLATFORMS: "SET_CRITERIA_PLATFORMS",
  SET_CRITERIA_INSTRUMENT_TYPES: "SET_CRITERIA_INSTRUMENT_TYPES",
  SET_CRITERIA_HEMISPHERES: "SET_CRITERIA_HEMISPHERES",
  SET_CRITERIA_ADHOC: "SET_CRITERIA_ADHOC",
  SET_CRITERIA_METADATA_FILTERS: "SET_CRITERIA_METADATA_FILTERS",
  RESET_CRITERIA_BLOCK: "RESET_CRITERIA_BLOCK",
  REMOVE_CRITERIA_BLOCK: "REMOVE_CRITERIA_BLOCK",
  SET_QUERY: "SET_QUERY",
  LOAD_EXAMPLE: "LOAD_EXAMPLE",
  LOAD_QUERY: "LOAD_QUERY",
  RESET_ALL: "RESET_ALL",
  SET_SEARCHING: "SET_SEARCHING",
  SET_QUERY_ID: "SET_QUERY_ID",
  SET_REQUEST: "SET_REQUEST",
  ADD_LOG_MESSAGE: "ADD_LOG_MESSAGE",
  SET_PROGRESS: "SET_PROGRESS",
  SET_DATA: "SET_DATA",
  ERROR: "ERROR",
};

export const emptyGround = {
  programs: [],
  platforms: [],
  instrument_types: [],
  ephemeris_metadata_filters: {},
};

export const emptySpace = {
  programs: [],
  platforms: [],
  instrument_types: ["footprint"],
  ephemeris_metadata_filters: {},
  hemisphere: [],
};

export const emptyEvent = {
  programs: [],
  platforms: [],
  instrument_types: [],
  ephemeris_metadata_filters: {},
};

export const emptyAdhoc = {
  locations: [
    {
      lat: 0,
      lon: 0,
    },
  ],
};

export const initialQuery = {
  start: "2019-01-01T00:00:00.000Z",
  end: "2019-01-01T00:59:59.000Z",
  conjunction_types: ["nbtrace"],
  ground: [
    emptyGround,
  ],
  space: [
    emptySpace,
  ],
  events: [],
  adhoc: [],
  max_distances: {
    "ground1-space1": 500,
  },
  ...((deployment_type === "staging" || deployment_type === "development") && { epoch_search_precision: 60 }),
};

/**
 * This is intended to map the query object that page components
 * reference into a valid JSON object that the API will accept.
 */
const initialState = {
  error: false,
  allDataSources: [],
  relevantDataSources: [],
  progress: null,
  queryID: null,
  searching: false,
  ignoreDistances: false,      // a flag to tell us if disable distances has been checked
  exampleLoaded: null,         // a container to hold which example (if any) has been loaded
  advancedDistancesBuffer: {}, // a container to hold advanced distance customizations
  queryObject: initialQuery,
  request: {
    search_request: {
      request_id: null,
      query: null,
      requested: null,
      request_type: null,
    },
    logs: null,
    search_result: {
      data_uri: null,
      result_count: null,
      result_file_deleted_timestamp: null,
      file_size: null,
      completed_timestamp: null,
      query_duration: null,
      error_condition: false,
    },
    data: null,
  },
};

// Given an example number, return the max_distances
// or if no value is provided, the max_distances from
// the initial query is returned.
export function findDefaultMaxDistance(exampleNumber) {
  if (exampleNumber) {
    switch (exampleNumber) {
      case (1): return example1.max_distances;
      case (2): return example2.max_distances;
      case (3): return example3.max_distances;
      case (4): return example4.max_distances;
      case (5): return example5.max_distances;
      case (6): return example6.max_distances;
      case (7): return example7.max_distances;
      case (8): return example8.max_distances;
    }
  }
  return initialQuery.max_distances;
}

export const dropdownSearchReducer = (state = initialState, action) => {
  if (deployment_type !== "production" && DEBUG) {
    console.log(action.type, action.payload);
  }

  switch (action.type) {
    case (dropdownSearchActions.INITIALIZE_FROM_ID): {
      return {
        ...state,
        ...action.payload,
      };
    }
    case (dropdownSearchActions.SET_DATA_SOURCES): {
      return {
        ...state,
        allDataSources: action.payload.sources,
      };
    }
    case (dropdownSearchActions.SET_DATETIME): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          start: action.payload.start,
          end: action.payload.end,
        },
      };
    }
    case (dropdownSearchActions.SET_ADVANCED_DISTANCES): {
      const shouldIgnoreDistances = action.payload.ignoreDistances;
      const newMaxDistances = action.payload.max_distances;
      // Here we're handling various cases and then clicked disable distances:
      // - we've loaded an example
      // - we're in the initial state
      // - we've loaded an example and customized it
      if (!shouldIgnoreDistances) {
        state.ignoreDistances = false;
        if (newMaxDistances && Object.keys(newMaxDistances).length > 0) {
          state.advancedDistancesBuffer = newMaxDistances;
          return {
            ...state,
            queryObject: {
              ...state.queryObject,
              max_distances: { ...state.advancedDistancesBuffer },
            },
          };
        } else {
          const newState = {
            ...state,
            queryObject: {
              ...state.queryObject,
              max_distances: { ...findDefaultMaxDistance(state.exampleLoaded), ...state.advancedDistancesBuffer },
            },
          };
          state.advancedDistancesBuffer = {};
          return newState;
        }
      } else {
        state.ignoreDistances = true;
        return {
          ...state,
          queryObject: {
            ...state.queryObject,
            max_distances: {},
          },
        };
      }
    }
    case (dropdownSearchActions.SET_CONJUNCTION_TYPES): {
      const newQueryObject = {
        ...state.queryObject,
        conjunction_types: [...action.payload],
      };

      return {
        ...state,
        queryObject: newQueryObject,
      };
    }
    case (dropdownSearchActions.TOGGLE_SUBMINUTE_PRECISION): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          epoch_search_precision: action.payload,
        },
      };
    }
    case (dropdownSearchActions.ADD_CRITERIA_BLOCK): {
      let newCriteria;
      let newQueryObject;
      if (action.payload.type === "ground") {
        newCriteria = { ...emptyGround };

        newQueryObject = {
          ...state.queryObject,
          ground: [...state.queryObject.ground, newCriteria],
        };
      } else if (action.payload.type === "space") {
        newCriteria = { ...emptySpace };
        newQueryObject = {
          ...state.queryObject,
          space: [...state.queryObject.space, newCriteria],
        };
      } else if (action.payload.type === "event") {
        newCriteria = { ...emptyEvent };
        newQueryObject = {
          ...state.queryObject,
          events: [...state.queryObject.events, newCriteria],
        };
      } else if (action.payload.type === "adhoc") {
        newCriteria = { ...emptyAdhoc };
        const existingAdhoc = state.queryObject?.adhoc ? state.queryObject.adhoc : [];
        newQueryObject = {
          ...state.queryObject,
          adhoc: [...existingAdhoc, newCriteria],
        };
      } else {
        Warn("No type of criteria block called " + action.payload.type);
      }

      let newMaxDistances = rebuildMaxDistancesFromQuery(newQueryObject);

      return {
        ...state,
        queryObject: {
          ...newQueryObject,
          max_distances: newMaxDistances,
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_PROGRAMS): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [
            ...state.queryObject[action.payload.type].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    programs: [...action.payload.value],
                  };
                }

                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_PLATFORMS): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [
            ...state.queryObject[action.payload.type].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    platforms: [...action.payload.value],
                  };
                }

                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_INSTRUMENT_TYPES): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [
            ...state.queryObject[action.payload.type].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    instrument_types: [...action.payload.value],
                  };
                }

                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_HEMISPHERES): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [
            ...state.queryObject[action.payload.type].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    hemisphere: [...action.payload.value],
                  };
                }

                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_ADHOC): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          ["adhoc"]: [
            ...state.queryObject["adhoc"].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    locations: [{
                      lat: action.payload.value[0],
                      lon: action.payload.value[1],
                    }],
                  };
                }
                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.SET_CRITERIA_METADATA_FILTERS): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [
            ...state.queryObject[action.payload.type].map(
              (criteriaBlock, index) => {
                if (index === action.payload.index) {
                  return {
                    ...criteriaBlock,
                    ephemeris_metadata_filters: Object.keys(action.payload.value).length === 0 ? {} : {
                      logical_operator: criteriaBlock.ephemeris_metadata_filters.logical_operator ? criteriaBlock.ephemeris_metadata_filters.logical_operator : "AND",
                      ...action.payload.value,
                    },
                  };
                }

                return { ...criteriaBlock };
              },
            ),
          ],
        },
      };
    }
    case (dropdownSearchActions.RESET_CRITERIA_BLOCK): {
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.type]: [...state.queryObject[action.payload.type].map(
            (criteriaBlock, index) => {
              if (index === action.payload.index && action.payload.type === "ground") {
                return { ...emptyGround };
              } else if (index === action.payload.index && action.payload.type === "space") {
                return { ...emptySpace };
              } else if (index === action.payload.index && action.payload.type === "events") {
                return { ...emptyEvent };
              } else if (index === action.payload.index && action.payload.type === "adhoc") {
                return { ...emptyAdhoc };
              }

              return { ...criteriaBlock };
            },
          )],
        },
      };
    }
    case (dropdownSearchActions.REMOVE_CRITERIA_BLOCK): {
      const newQueryObject = {
        ...state.queryObject,
        [action.payload.type]: [...state.queryObject[action.payload.type].filter(
          (criteriaBlock, index) => index !== action.payload.index,
        )],
      };

      let newMaxDistances = rebuildMaxDistancesFromQuery(newQueryObject);

      return {
        ...state,
        queryObject: {
          ...newQueryObject,
          max_distances: newMaxDistances,
        },
      };
    }
    case (dropdownSearchActions.SET_QUERY): {
      return {
        ...state,
        queryID: action.payload.queryID ?
          action.payload.queryID :
          action.payload.query["request_id"] ?
            action.payload.query["request_id"] :
            null,
        queryObject: {
          ...action.payload.query,
        },
      };
    }
    case (dropdownSearchActions.LOAD_EXAMPLE): {
      return {
        ...state,
        ignoreDistances: false,
        exampleLoaded: action.payload.exampleNumber,
        queryObject: {
          ...action.payload.exampleQuery,
        },
      };
    }
    case (dropdownSearchActions.ADD_LOG_MESSAGE): {
      return {
        ...state,
        request: {
          ...state.request,
          logs: state.request.logs !== null ? [...state.request.logs, action.payload.message] : [action.payload.message],
        },
      };
    }
    case (dropdownSearchActions.SET_PROGRESS): {
      return {
        ...state,
        progress: action.payload.progress,
      };
    }
    case (dropdownSearchActions.RESET_ALL): {
      return {
        ...initialState,
        allDataSources: state.allDataSources,
      };
    }
    case (dropdownSearchActions.SET_QUERY_ID): {
      return {
        ...state,
        queryID: action.payload.queryID,
        queryObject: {
          ...state.queryObject,
          request_id: action.payload.queryID,
        },
      };
    }
    case (dropdownSearchActions.SET_REQUEST): {
      // If the loaded search request had 0 max_distances, it was ignored.
      // set the state of the ignored flag to reflect that original query state.
      if (action?.payload?.requestInfo?.search_request?.query?.max_distances) {
        state.ignoreDistances = Object.keys(action.payload.requestInfo.search_request.query.max_distances).length === 0;
      }
      return {
        ...state,
        ignoreDistances: state.ignoreDistances,
        request: {
          ...state.request,
          ...action.payload.requestInfo,
        },
      };
    }
    case (dropdownSearchActions.SET_DATA): {
      return {
        ...state,
        request: {
          ...state.request,
          ...action.payload,
        },
      };
    }
    case (dropdownSearchActions.ERROR): {
      // This error flag is meant for times when the API can"t fetch data sources.
      // There"s no way to unset the error - the user will have to refresh the page.
      // If there"s a case where we want to clear the flag, read the new value from
      // action.payload and assign it below.
      return {
        ...state,
        error: true,
      };
    }
    case (dropdownSearchActions.SET_SEARCHING): {
      if (action.payload === true) {
        // started searching, so clear logs and search result
        return {
          ...state,
          searching: action.payload,
          request: {
            ...state.request,
            logs: [],
            search_result: initialState.request.search_request,
          },
        };
      } else {
        // finished searching, only update searching state
        return {
          ...state,
          searching: action.payload,
        };
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};


