// Importing Components from node_modules
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Importing Project-defined Components
import AuroraXRoutes from './components/Routes';

// Importing Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AuroraXRoutes />
  </BrowserRouter>,
);
