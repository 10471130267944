// Importing Components from node_modules
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router";

export const AuthenticateAccountContext = createContext([]);

const AuthenticateAccountProvider = (props) => {
  let navigate = useNavigate();
  const [accountCredentials, setAccountCredentials] = useState({});
  const [errorInfo, setErrorInfo] = useState('');
  const [accountInfo, setAccountInfo] = useState({});
  const [loading, setLoading] = useState(false);

  function updateAccountInfo() {
    if (typeof accountCredentials === 'undefined' || Object.keys(accountCredentials).length === 0) { return; }

    checkAccountCredentials(accountCredentials)
      .then((data) => {
        setAccountInfo(data);
        setLoading(false);
      });
  }

  const checkAccountCredentials = useCallback((accountCredentials) => {
    const dataPromise = axios
      .post("/api/v1/authenticate", accountCredentials)
      .then(resp => {
        let body = resp.data;
        body.authorization = resp.headers['authorization'];
        return body;
      }).catch((err) => {
        if (err && err.response && (err.response.status === 401 || err.response.status === 429 || err.response.status === 403)) {
          setErrorInfo(err.response.status);
          setLoading(false);
        }
      });
    return dataPromise;
  }, []);

  useEffect(() => {
    updateAccountInfo();
  }, [accountCredentials]);

  // Set cookie containing relevant account info that can be retrieved through refresh
  // Expires after 30 min of remaining on the same page
  useEffect(() => {
    if (accountInfo && Object.keys(accountInfo).length) {
      sessionStorage.setItem('account_info', JSON.stringify(accountInfo));
      sessionStorage.setItem('auth', true);

      setAccountInfo({});
      navigate("/account/manageData");
    }
  }, [accountInfo]);

  return (
    <AuthenticateAccountContext.Provider value={[accountCredentials, setAccountCredentials, errorInfo, setErrorInfo, loading, setLoading]}>
      {props.children}
    </AuthenticateAccountContext.Provider>
  );
};

export default AuthenticateAccountProvider;