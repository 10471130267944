// Importing Components from node_modules
import React, { useState } from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";

// Importing Icon & Images
import { FaInfoCircle, FaAngleRight } from "react-icons/fa";

// Importing styles
import "../../styles/alerts/DevAlert.scss";

const DevAlert = ({ alertType, children, alertColor }) => {
  const [show, setShow] = useState(true);

  // If the alert has not been closed, show it on the webpage
  if (show) {
    return (
      <BootstrapAlert
        dismissible
        className={`dev-alert ${alertColor}`}
        onClose={() => setShow(false)}
      >
        <p>
          <FaInfoCircle
            className="info-icon"
            style={{
              height: "18px",
              width: "18px",
              margin: "0 5px 0 0",
            }}
          />
          {"  "}
          {alertType}
          <FaAngleRight
            style={{
              height: "18px",
              width: "18px",
              margin: "0 3px",
            }}
          />
          {children}
        </p>
      </BootstrapAlert>
    );
  }

  // If alert has been closed, return React Fragment (nothing) else page will not render correctly
  return <></>;
};

export default DevAlert;
