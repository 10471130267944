import React, { useState, useEffect } from 'react';
import MultiSelect from '../MultiSelect';

import { dropdownSearchActions, useDropdownSearchContext } from '../context/stateManager';
import _ from 'lodash';

function getInstrumentTypeOptions(dataSources, type, programs, platforms) {
  let instrumentTypes;

  if (dataSources) {
    instrumentTypes = dataSources
      .filter((dataSource) => {
        if (type === 'ground') {
          return dataSource.source_type === 'ground';
        } else if (type === 'space') {
          if (dataSource.source_type === 'leo' || dataSource.source_type === 'heo' || dataSource.source_type === 'lunar') {
            return true;
          } else {
            return false;
          }
        } else if (type === 'events') {
          return dataSource.source_type === 'event_list';
        } else {
          return false;
        }
      })
      .filter((dataSource) => { // always filter the instrument to the selected programs
        if (programs) {
          if (programs.length === 0) {
            return true;
          }
          return programs.includes(dataSource.program);
        }
        return true;
      })
      .filter((dataSource) => { // and the selected instrument types
        if (platforms) {
          if (platforms.length === 0) {
            return true;
          }
          return platforms.includes(dataSource.platform);
        }
        return true;
      })
      .map((dataSource) => dataSource.instrument_type);
  }

  return [...new Set(instrumentTypes)]
    .sort()
    .map((instrumentType) => ({
      value: instrumentType,
      label: instrumentType,
    }));
}

export default function SelectInstrumentTypes({ type, index }) {
  const { state, dispatch } = useDropdownSearchContext();
  const [instrumentTypeOptions, setInstrumentTypeOptions] = useState([]);
  const [selected, setSelected] = useState(state.queryObject[type][index].instrument_types);
  const [changeFlag, setChangeFlag] = useState(true);

  /*
    Following function is used to remove the "footprint" space instrument type from the available
    choices if there is another optional available besides that one (footprint)
  */
  useEffect(() => {
    if (type === 'space'
      && instrumentTypeOptions.indexOf(
        instrumentTypeOptions.filter((x) => x.value === 'footprint')[0],
      ) !== -1
    ) {
      instrumentTypeOptions.splice(
        instrumentTypeOptions.indexOf(
          instrumentTypeOptions.filter((x) => x.value === 'footprint')[0],
        ), 1,
      );
    }
    setChangeFlag(changeFlag => !changeFlag);
  }, [instrumentTypeOptions, type]);

  // Called when an option on the dropdown is selected
  useEffect(() => {
    setSelected([...state.queryObject[type][index].instrument_types.map(
      (instrumentType) => ({ value: instrumentType, label: instrumentType }
      ))]);
  }, [state.queryObject[type][index].instrument_types]);

  useEffect(() => {
    setInstrumentTypeOptions([...getInstrumentTypeOptions(
      state.allDataSources,
      type,
      state.queryObject[type][index].programs,
      state.queryObject[type][index].platforms,
    )]);
  }, [state.allDataSources, type, state.queryObject[type][index].programs, state.queryObject[type][index].platforms]);

  const handleOnChange = (selections) => {
    if (!_.isEqual(selected, selections)) {
      setSelected(selections);
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_INSTRUMENT_TYPES,
        payload: {
          type: type,
          index: index,
          value: selections.map((selection) => selection.value),
        },
      });
    }
  };

  return (
    <MultiSelect
      options={instrumentTypeOptions}
      value={selected}
      type={type}
      changeFlag={changeFlag}
      sourceType='instrument'
      onChange={handleOnChange}
      menuStyleWidth='275px'
      sticky={false}
      includeSelectDeselectFooter={true}
      searchable={true}
    />
  );
}
