import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader, faMagnifyingGlass, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Error, Warn } from '../components/Toasts';

import { conjunctionSearchUrl, conjunctionSearchResponseWs, conjunctionSearchResponse } from '../../../utils/auroraXapi';
import { useRef } from 'react';
import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';
import { useEffect } from 'react';

// timeout for the initial POST to submit a conjunction search request
const initialPOSTTimeout = 30000; // 30 seconds

// ping interval for websockets (to keep them open without having to
// increase timeouts on the API)
const pingInterval = 10000;  // 10 seconds

// debugging flag for progress bar
const debug_progress = false;  // change to true to enable console.log messages for the progress

// default query stage messages
const defaultQueryStageMessages = [
  new RegExp("Found ([0-9]+) single epoch events"),
  new RegExp("Reduced single events into ([0-9]+) continuous intervals"),
  new RegExp("Searching for super events across all data source combinations"),
  new RegExp("Found ([0-9]+) super events"),
  new RegExp("Saving results"),
];

export default function SearchButton(props) {
  const { state, dispatch } = useDropdownSearchContext();

  function waitForSocketConnection(socket, callback) {
    setTimeout(() => {
      if (socket.readyState === 1) {
        if (callback != null) {
          callback();
        }
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 5); // wait 5 millisecond for the connection
  }

  function determine_stages(query, query_count = null, remove_initial_messages = null) {
    // default of 2 states - "starting conjunction search", and "finished search"
    let stages = {
      "num_query_stages": 0,
      "expected_messages": 0,
      "remaining_messages": [
        new RegExp("Starting conjunction search for query"),
      ],
    };

    // derive the number of stages we expect
    //
    // a stage for every conjunction type. We won't know the sub
    // stage count (ie. number of database queries) until the
    // search has started
    if (query.conjunction_types.length === 0 || query.conjunction_types.length === 3) {
      // all conjunction types
      stages["num_query_stages"] = 3;
      query_count = Math.floor(query_count / stages["num_query_stages"]);

      // add nbtrace messages
      stages["remaining_messages"].push(new RegExp("Start search for nbtrace"));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);

      // add sbtrace messages
      stages["remaining_messages"].push(new RegExp("Start search for sbtrace"));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);

      // add geographic messages
      stages["remaining_messages"].push(new RegExp("Start search for geographic"));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);
    } else if (query.conjunction_types.length === 1) {
      // only one conjunction type
      stages["num_query_stages"] = 1;
      query_count = Math.floor(query_count / stages["num_query_stages"]);
      stages["remaining_messages"].push(new RegExp("Start search for " + query.conjunction_types[0]));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);
    } else if (query.conjunction_types.length === 2) {
      // two conjunction types
      stages["num_query_stages"] = 2;
      query_count = Math.floor(query_count / stages["num_query_stages"]);

      // add messages for type 1
      stages["remaining_messages"].push(new RegExp("Start search for " + query.conjunction_types[0]));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);

      // add message for type 2
      stages["remaining_messages"].push(new RegExp("Start search for " + query.conjunction_types[1]));
      if (query_count !== null) {
        stages["remaining_messages"].push(new RegExp("Querying the database ([0-9]+) times"));
        for (let i = 0; i < query_count; i++) {
          stages["remaining_messages"].push(new RegExp("Querying database for criteria combination"));
        }
      }
      stages["remaining_messages"] = stages["remaining_messages"].concat(defaultQueryStageMessages);
    } else {
      console.log("unknown number of conjunction type stages, no idea how I got " +
        "here (conjunction_types.length=" + query.conjunction_types.length + ")");
    }

    // add in final messages
    stages["remaining_messages"].push(new RegExp("Search completed"));
    stages["remaining_messages"].push(new RegExp("File size is"));
    stages["remaining_messages"].push(new RegExp("Finished search in"));

    // set expected messages count
    stages["expected_messages"] = stages["remaining_messages"].length;

    // remove any lines if we're partway in already
    if (remove_initial_messages !== null) {
      stages["remaining_messages"].splice(0, remove_initial_messages);
    }

    // return
    return stages;
  }

  function validatePreflightSearchRequest(query) {
    // init
    const validityObj = {
      valid: true,
      errorMessage: '',
    };

    // evaluate validity
    let item = null;
    try {
      for (let i = 0; i < query.ground?.length; i++) {
        item = query.ground[i];
        if ((!('programs' in item) || item.programs.length == 0) && (!('platforms' in item) || item.platforms.length == 0) && (!('instrument_types' in item) || item.instrument_types.length == 0)) {
          validityObj.valid = false;
          validityObj.errorMessage = 'Please refine your ground criteria block to specify at least one programs, platforms, or instrument types parameter.';
        }
      }
      for (let i = 0; i < query.space?.length; i++) {
        item = query.space[i];
        if ((!('programs' in item) || item.programs.length == 0) && (!('platforms' in item) || item.platforms.length == 0)) {
          validityObj.valid = false;
          validityObj.errorMessage = 'Please refine your space criteria block to specify at least one programs or platforms parameter.';
        }
      }
    } catch (err) {
      // unknown error happened
      console.log(err);
      validityObj.valid = false;
      validityObj.errorMessage = "Unknown error during query validity check, please contact the administrators. Sorry for the inconvenience.";
    }

    // return
    return validityObj;
  }

  // We hold refs to the socket connections and the intervals used to ping
  // them so that we can close/clear them from outside the search function
  // when the cancel button is clicked
  const websocketResultConnectionRef = useRef();
  const websocketResultLogsConnectionRef = useRef();
  const websocketDataConnectionRef = useRef();
  const resultsPingIntervalRef = useRef();
  const logsPingIntervalRef = useRef();
  const dataPingIntervalRef = useRef();

  const cancelSearch = () => {
    // We can't actually send the DELETE request to cancel a query without an Administrator's API key.
    // So, we just close the sockets, clear the ping intervals, and reset the progress bar on the front end,
    // effectively closing the page off to responses from the API about the query we canceled.
    dispatch({
      type: dropdownSearchActions.SET_PROGRESS,
      payload: {
        progress: null,
      },
    });
    dispatch({
      type: dropdownSearchActions.SET_SEARCHING,
      payload: false,
    });
    websocketResultConnectionRef.current.close();
    websocketResultLogsConnectionRef.current.close();
    websocketDataConnectionRef.current.close();
    clearInterval(resultsPingIntervalRef.current);
    clearInterval(logsPingIntervalRef.current);
    clearInterval(dataPingIntervalRef.current);
  };

  /* Here we generate the request ID, initialize the progress bar, and then
  toggle the global state searching flag to "true". There is a useEffect watching the
  searching flag and that effect will trigger the actual HTTP search request.
  */
  function searchForData() {
    // validate query so that we're ok to proceed
    const validityObj = validatePreflightSearchRequest(state.queryObject);
    if (validityObj.valid === false) {
      Error(validityObj.errorMessage);
      return;
    }

    // generate the request ID so it's easier for us to track for
    // websockets (instead of letting the API determine it)
    let newId = uuidv4();
    dispatch({
      type: dropdownSearchActions.SET_QUERY_ID,
      payload: {
        queryID: newId,
      },
    });

    // init the progress bar
    dispatch({
      type: dropdownSearchActions.SET_PROGRESS,
      payload: {
        progress: 0,
      },
    });

    // set searching flag
    dispatch({
      type: dropdownSearchActions.SET_SEARCHING,
      payload: true,
    });
  }

  useEffect(() => {
    if (state.searching === true) {
      // determine progress stages object
      let curr_progress = 0;
      let stages = determine_stages(state.queryObject);
      let curr_stage = 0;
      let query_count_regex = new RegExp("Querying the database ([0-9]+) times");
      let stages_updated = false;
      if (debug_progress) {
        console.log("Expected stages: " + stages.expected_messages);
      }

      // This websocket implementation is a leaky abstraction. They are really meant
      // for text messaging (like a chat app) but we are using them to
      // eliminate the polling nature of the async connections. In doing so, we
      // introduce some knowledge this webapp needs to know about how the
      // data is arriving on the wire. For example, search result data
      // comes as one big array, but the logs come as separate objects, sent one
      // at a time. This is due to the implementation of the API, and
      // this webapp needs to know about that implementation, hence the
      // leaky abstraction. See here: https://en.wikipedia.org/wiki/Leaky_abstraction
      const websocketResultConnection = new WebSocket(`${conjunctionSearchResponseWs}/${state.queryID}`);
      websocketResultConnectionRef.current = websocketResultConnection;
      const resultsPingInterval = setInterval(() => websocketResultConnection.send("ping"), pingInterval);
      resultsPingIntervalRef.current = resultsPingInterval;
      websocketResultConnection.onmessage = (evt) => {
        // search result info comes as one single object - this is the object that holds the
        // completed_timestamp, uri, error condition, file size, query duration, result count, and deleted timestamp.
        // It does not include logs, conjunction results, or info about the request.
        let requestInfo = JSON.parse(evt.data);

        dispatch({
          type: dropdownSearchActions.SET_REQUEST,
          payload: {
            requestInfo: {
              search_result: requestInfo,
            },
          },
        });

        dispatch({ type: dropdownSearchActions.SET_SEARCHING, payload: false });

        // the single data message has been recieved, we can close this websocket now
        clearInterval(resultsPingInterval);
        websocketResultConnection.close();
      };

      const websocketResultLogsConnection = new WebSocket(`${conjunctionSearchResponseWs}/${state.queryID}/logs`);
      websocketResultLogsConnectionRef.current = websocketResultLogsConnection;
      const logsPingInterval = setInterval(() => websocketResultLogsConnection.send("ping"), pingInterval);
      logsPingIntervalRef.current = logsPingInterval;
      websocketResultLogsConnection.onmessage = (evt) => {
        // a log message has arrived, parse it into JSON
        const logMessage = JSON.parse(evt.data);

        // while we're here, let's just post the error message to the Toast
        if (logMessage.level === 'error') {
          Error(logMessage.summary);
        }

        // logs come as separate objects that are appended to the global state's logs array
        dispatch({
          type: dropdownSearchActions.ADD_LOG_MESSAGE,
          payload: {
            message: logMessage,
          },
        });

        // some debugging messages for the progress bar
        if (debug_progress) {
          console.log(logMessage.summary);
          console.log(...stages.remaining_messages);
        }

        // determine the progress bar "stage" we're currently in
        //
        // start by checking if this message is the first one that will
        // tell us the query count
        if (stages_updated === false && query_count_regex.test(logMessage.summary)) {
          // this is the first query count line, update the list of expected messages
          let extracted_query_count = 1;
          extracted_query_count = parseInt(logMessage.summary.match(query_count_regex)[1]);
          stages = determine_stages(state.queryObject, extracted_query_count, 2);
          stages_updated = true;

          // some debugging messages for the progress bar
          if (debug_progress) {
            console.log("updated stages");
            console.log("Expected stages: " + stages.expected_messages);
            console.log(...stages.remaining_messages);
          }
        }

        // assume that we are either in the beginning messages, or the stages variable has now
        // been updated with the correct number of expected messages
        //
        // go through each remaining message, looking for the right one
        for (let i = 0; i < stages.remaining_messages.length; i++) {
          if (stages.remaining_messages[i].test(logMessage.summary)) {
            // matches, remove messages up to, and including, this message
            if (debug_progress) {
              console.log("match, removing message and incrementing stage");
            }
            stages.remaining_messages.shift(0, i);
            curr_stage += i + 1;
            break;
          }
        }

        // set the new progress bar value, and update the context
        curr_progress = Math.floor((curr_stage / stages.expected_messages) * 100.0);
        dispatch({
          type: dropdownSearchActions.SET_PROGRESS,
          payload: {
            progress: curr_progress,
          },
        });

        // close the websocket once we'e made it to 100% progress
        if (curr_progress == 100) {
          clearInterval(logsPingInterval);
          websocketResultLogsConnection.close();
        }

        // some debugging messages
        if (debug_progress) {
          console.log(...stages.remaining_messages);
          console.log("stage: " + curr_stage + " (" + curr_progress + "%)");
          console.log("---------------");
        }
      };

      const websocketDataConnection = new WebSocket(`${conjunctionSearchResponseWs}/${state.queryID}/data`);
      websocketDataConnectionRef.current = websocketDataConnection;
      const dataPingInterval = setInterval(() => websocketDataConnection.send("ping"), pingInterval);
      dataPingIntervalRef.current = dataPingInterval;
      websocketDataConnection.onmessage = (evt) => {
        // If we can add some sort of event listener to the data table addRow
        // then we can push even evt.data object directly into the table
        // and modify the API to send each event individually (preferred, like result logs).
        // ... but because we add all rows in one call, and because there
        // is no obvious connection between this context and the datatable
        // object, then we send a stream of objects from the API -- the API
        // has been tuned to work with my limitation of the DataTables.net API.
        // This is unfortunate and should be fixed one day.

        // all result data comes in one message
        let searchData = evt.data ? JSON.parse(evt.data) : [];

        dispatch({
          type: dropdownSearchActions.SET_DATA,
          payload: {
            data: searchData,
          },
        });

        // At this point we've received results from the websocket, so we can close it
        clearInterval(dataPingInterval);
        websocketDataConnection.close();
      };

      // we have to wait for the websocket connection to be established. In staging and production,
      // the API is so fast, that query results are returned even before the server has had enough
      // time to establish these sessions. This strategy prevents this additional race condition.
      waitForSocketConnection(websocketDataConnection, () => {
        // Do the actual search AFTER the websocket data connection is established
        axios
          .post(conjunctionSearchUrl, state.queryObject, { timeout: initialPOSTTimeout })
          .catch((err) => {
            Error(`${err.response.data.error_code}: ${err.response.data.error_message}`);
            dispatch({
              type: dropdownSearchActions.SET_SEARCHING,
              payload: false,
            });
          });
      });
    }
  }, [state.searching]);

  useEffect(() => {
    if (state.progress === 100) {
      // at this point we have the data but our state.request.search_result object isn't up to date,
      // so we get an update from the API
      axios.get(`${conjunctionSearchResponse}/${state.queryID}`)
        .then(resp => {
          dispatch({
            type: dropdownSearchActions.SET_REQUEST,
            payload: {
              requestInfo: {
                search_request: resp.data.search_request,
                search_result: resp.data.search_result,
              },
            },
          });
        })
        .catch(() => {
          Warn("Couldn't fetch some request info from the server - some stats may be missing.");
        });
    }
  }, [state.progress]);

  if (!state.searching) {
    return (
      <Button
        variant="dark"
        size="lg"
        ref={props.searchRef}
        className="search-button-not-searching"
        onClick={searchForData}
      >
        Search
        {' '}
        <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
      </Button>
    );
  }

  return (
    <>
      <div className="btn-group search-btn-group" role="group">
        <Button
          variant="dark"
          size="lg"
          className="search-button-searching"
          disabled>
          <FontAwesomeIcon icon={faLoader} fixedWidth spin style={{ marginBottom: "1px" }} />
        </Button>

        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Cancel search
            </Tooltip>
          }>
          <Button
            variant="info"
            size="lg"
            className="cancel-button"
            onClick={cancelSearch}
          >
            <FontAwesomeIcon icon={faXmark} size="lg" fixedWidth />
          </Button>
        </OverlayTrigger>
      </div>
    </>
  );
}
