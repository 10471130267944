// Importing Components from node_modules
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// Importing Project-Defined Components
import { createAccountsUrl } from '../../../utils/auroraXapi';

export const createAccountContext = createContext([]);

const createAccountProvider = (props) => {
  const [accountSetup, setAccountSetup] = useState({});
  const [accountResponse, setAccountResponse] = useState({});
  const [errorInfo, setErrorInfo] = useState('');
  const [requestComplete, setRequestComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  function createAccount() {
    setErrorInfo('');
    setRequestComplete(false);

    if (typeof accountSetup === 'undefined' || Object.keys(accountSetup).length === 0) { return; }

    createAccountRequest(accountSetup).then((data) => {
      setRequestComplete(true);
      setAccountResponse(data);
      setLoading(false);
    });
  }

  const createAccountRequest = useCallback((accountSetup) => {
    let date = new Date();
    date.toISOString();

    let tempData = {
      "email_address": accountSetup.email,
      "first_name": accountSetup.fname,
      "last_name": accountSetup.lname,
      "agree_terms": date,
      "password": accountSetup.password,
    };

    const dataPromise = axios
      .post(createAccountsUrl, tempData)
      .then((resp) => resp.data)
      .catch((err) => {
        if (err && err.response && (err.response.status === 400 || err.response.status === 409 || err.response.status === 429)) {
          setErrorInfo(err.response.status);
        }
      });
    return dataPromise;
  }, []);

  useEffect(() => {
    createAccount();
  }, [accountSetup]);

  return (
    <createAccountContext.Provider value={[accountSetup, setAccountSetup, errorInfo, setErrorInfo, accountResponse, requestComplete, loading, setLoading]}>
      {props.children}
    </createAccountContext.Provider>
  );
};

export default createAccountProvider;