import React, { useState, useEffect } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { JsonViewer } from '@textea/json-viewer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader, faDownload, faUndo } from "@fortawesome/pro-regular-svg-icons";

import _ from "lodash";

import { conjunctionSearchResponse } from '../../../utils/auroraXapi';
import {
    dropdownSearchActions,
    emptyEvent,
    emptyGround,
    emptySpace,
    initialQuery,
    useDropdownSearchContext,
} from "./context/stateManager";

const patchQuery = (query) => {
    let patchedQuery = {};
    let queryClone = _.cloneDeep(query);
    let queryKeys = Object.keys(queryClone);
    let initialQueryClone = _.cloneDeep(initialQuery);
    let initialQueryKeys = Object.keys(initialQueryClone);

    for (let key of initialQueryKeys) {
        if (queryKeys.includes(key)) {
            patchedQuery[key] = _.cloneDeep(queryClone[key]);
        } else {
            patchedQuery[key] = _.cloneDeep(initialQueryClone[key]);
        }
    }
    return patchedQuery;
};

const LoadConjunctionModal = ({ show, setShow, searchRef }) => {
    const { state, dispatch } = useDropdownSearchContext();

    const DEFAULT_LOADING_STATE = {
        loading: "initialized",
        alertMessage: null,
        originalQuery: null,
        patchedQuery: null,
        idValue: "",
    };
    const [loadingState, setLoadingState] = useState(DEFAULT_LOADING_STATE);

    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(() => {
        setDisableBtn(state.searching || loadingState.loading === "loading");
    }, [state.searching, loadingState.loading]);

    const updateQuery = (query) => {
        setLoadingState({ ...loadingState, patchedQuery: patchQuery(query) });
    };

    // This function prepares the query by setting the correct number of empty criteria blocks
    // that we will later fill with values from the loaded query.
    const updateCriteriaBlocks = (query) => {
        let queryKeys = Object.keys(query);
        let numCriteria = {
            "ground": 0,
            "space": 0,
            "events": 0,
        };
        let groundArray = [];
        let spaceArray = [];
        let eventsArray = [];

        if (queryKeys.includes("ground")) {
            numCriteria["ground"] = query["ground"].length;
        }
        if (queryKeys.includes("space")) {
            numCriteria["space"] = query["space"].length;
        }
        if (queryKeys.includes("events")) {
            numCriteria["events"] = query["events"].length;
        }

        for (let i = 0; i < numCriteria["ground"]; i++) {
            groundArray.push(emptyGround);
        }
        for (let i = 0; i < numCriteria["space"]; i++) {
            spaceArray.push(emptySpace);
        }
        for (let i = 0; i < numCriteria["events"]; i++) {
            eventsArray.push(emptyEvent);
        }

        dispatch({
            type: dropdownSearchActions.SET_QUERY,
            payload: {
                queryID: query.request_id,
                query: {
                    ...initialQuery,
                    ground: groundArray,
                    space: spaceArray,
                    events: eventsArray,
                },
            },
        });
    };

    useEffect(() => {
        if (loadingState.patchedQuery) {
            dispatch({
                type: dropdownSearchActions.SET_QUERY,
                payload: {
                    queryID: loadingState.patchedQuery.request_id,
                    query: {
                        ...loadingState.patchedQuery,
                    },
                },
            });
        }
    }, [loadingState.patchedQuery]);

    const getRequestDetails = () => {
        setLoadingState({ ...loadingState, loading: "loading" });
        axios
            .get(`${conjunctionSearchResponse}/${loadingState.idValue}`)
            .then(response => {
                // We have to add enough empty criteria blocks in the query to match the number of criteria blocks
                // in the loaded query. If we don't do this, the crtieria and query may not load fully.
                updateCriteriaBlocks({ ...response["data"]["search_request"]["query"] });
                updateQuery({ ...response["data"]["search_request"]["query"] });

                dispatch({
                    type: dropdownSearchActions.SET_REQUEST,
                    payload: {
                        requestInfo: { ...response["data"], data: [] },
                    },
                });

                // get the conjunction results
                axios.get(`${conjunctionSearchResponse}/${loadingState.idValue}/data`)
                    .then(dataResponse => {
                        if (dataResponse.data.error) {
                            setLoadingState({
                                ...loadingState,
                                loading: "no-data-file",
                                alertMessage: "This conjunction search is outdated and results are no longer available. Run the search again for new results?",
                                originalQuery: response["data"]["search_request"]["query"],
                            });
                        } else {
                            dispatch({
                                type: dropdownSearchActions.INITIALIZE_FROM_ID,
                                payload: {
                                    queryID: loadingState.idValue,
                                    queryObject: {
                                        ...response.data.search_request.query,
                                    },
                                    request: {
                                        ...response.data,
                                        data: [...dataResponse.data.result],
                                    },
                                },
                            });

                            let alert = () => {
                                return (
                                    <>
                                        Conjunction results were successfully loaded for request ID
                                        <strong>{' '}{loadingState.idValue}</strong>. You may safely
                                        exit this modal.
                                    </>
                                );
                            };
                            setLoadingState({
                                ...loadingState,
                                loading: "loaded",
                                alertMessage: alert(),
                                originalQuery: { ...response["data"]["search_request"]["query"] },
                            });
                        }
                    })
                    .catch(() => {
                        setLoadingState({
                            ...loadingState,
                            loading: "no-data-file",
                            alertMessage: "This conjunction search is outdated and results are no longer available. Run the search again for new results?",
                            originalQuery: { ...response["data"]["search_request"]["query"] },
                        });
                    });
            })
            .catch(function (err) {
                if (err.response && err.response.status && err.response.status === 404) {
                    setLoadingState({
                        ...loadingState,
                        originalQuery: null,
                        loading: "invalid-request-id",
                        alertMessage: "Could not find a conjunction search with this ID.",
                    });
                } else {
                    setLoadingState({
                        ...loadingState,
                        originalQuery: null,
                        loading: "error",
                        alertMessage: "An unknown error occurred while searching.",
                    });
                }
            });
    };

    const ALERT_COLOURS = {
        "loaded": "success",
        "invalid-request-id": "danger",
        "error": "danger",
        "no-data-file": "warning",
        "initialized": "info",
        "found-request-info": "info",
        "re-run-query": "info",
    };

    const handleInputChange = (e) => {
        setLoadingState({ ...loadingState, idValue: e.target.value });
    };

    const handleLoadQuery = () => {
        if (loadingState.idValue) {
            getRequestDetails();
        }
    };

    const handleRerunQuery = () => {
        searchRef.current.click();
        setLoadingState({
            ...loadingState,
            loading: "re-run-query",
            alertMessage: "Your query is being run again and results will appear in the conjunction search page shortly. You may safely exit this modal.",
        });
    };

    const handleClose = () => {
        setShow(false);
        document.querySelector('body').classList.remove('modal-open');
    };

    const handleOpen = () => {
        setLoadingState(DEFAULT_LOADING_STATE);
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            onShow={handleOpen}
            scrollable>
            <Modal.Header closeButton>
                <h3>Load Conjunction Search Results</h3>
            </Modal.Header>
            <Modal.Body>
                <p style={{ color: "black", fontSize: "14px" }}>
                    Enter the request ID from a previous conjunction search to load
                    its parameters and any results.
                </p>
                <p style={{ color: "black", fontSize: "14px" }}>
                    If you're not sure where to find the request ID, click on the "Share"
                    button behind this modal window and another modal will pop up. The
                    request ID will be there for you to copy.
                </p>

                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: "14px", fontWeight: "bold" }}>Request ID</Form.Label>
                        <Form.Control
                            type="search"
                            placeholder="eg. d80039ce-9a5c-476b-aa77-9f48930a7553"
                            value={loadingState.idValue}
                            onChange={handleInputChange}
                            style={{ fontSize: "14px" }} />
                    </Form.Group>
                    <Button
                        className="mb-2 w-100"
                        variant="outline-dark"
                        style={{ fontSize: "14px" }}
                        onClick={handleLoadQuery}
                        disabled={disableBtn}>
                        {
                            disableBtn ?
                                <>
                                    Loading<FontAwesomeIcon icon={faLoader} fixedWidth spin style={{ margin: "2px 0 0 5px" }} />
                                </>
                                :
                                <>
                                    Load request ID<FontAwesomeIcon icon={faDownload} fixedWidth style={{ margin: "2px 0 0 5px" }} />
                                </>
                        }
                    </Button>
                </Form>
                <Alert
                    className="mt-1"
                    style={{ fontSize: "14px" }}
                    variant={ALERT_COLOURS[loadingState.loading]}
                    transition={false}
                    hidden={loadingState.loading === "initialized" || loadingState.loading === "loading"}>
                    {loadingState.alertMessage}
                    {
                        loadingState.loading === "no-data-file" &&
                        <Button
                            className="mt-3 w-100"
                            variant="warning"
                            style={{ fontSize: "14px" }}
                            onClick={handleRerunQuery}>
                            Re-run search
                            <FontAwesomeIcon icon={faUndo} fixedWidth style={{ marginTop: "-3px", marginLeft: "10px" }} />
                        </Button>
                    }
                </Alert>
                {
                    loadingState.originalQuery &&
                    <JsonViewer
                        value={loadingState.originalQuery}
                        style={{ fontSize: "12px", maxHeight: 200, overflowY: "scroll" }}
                        enableClipboard={false}
                        quotesOnKeys={false}
                        displayDataTypes={false}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LoadConjunctionModal;