import React, { useState, useEffect } from 'react';
import MultiSelect from '../MultiSelect';

import { dropdownSearchActions, useDropdownSearchContext } from '../context/stateManager';
import _ from 'lodash';

// Retrieving options based on if the select dropdown is for space or ground
function getProgramOptions(dataSources, type) {
  let programs;

  if (dataSources) {
    programs = dataSources
      .filter((dataSource) => { // filter for ground or space
        if (type === 'ground') {
          return dataSource.source_type === 'ground';
        } else if (type === 'space') {
          if (dataSource.source_type === 'leo' || dataSource.source_type === 'heo' || dataSource.source_type === 'lunar') {
            return true;
          } else {
            return false;
          }
        } else if (type === 'events') {
          return dataSource.source_type === 'event_list';
        } else {
          return false;
        }
      })
      .map((dataSource) => dataSource.program);
  }
  return [...new Set(programs)]
    .sort()
    .map((program) => ({
      value: program,
      label: program,
    }));
}

export default function SelectPrograms({ type, index }) {
  const { state, dispatch } = useDropdownSearchContext();
  const [programOptions, setProgramOptions] = useState([]);
  const [selected, setSelected] = useState(state.queryObject[type][index].programs);

  // Tracks which option has been selected
  useEffect(() => {
    setSelected([...state.queryObject[type][index].programs.map((program) => ({ value: program, label: program }))]);
  }, [state.queryObject[type][index].programs]);

  // Retrieves and sets the program options based on data sources & type (ground/space)
  useEffect(() => {
    setProgramOptions([...getProgramOptions(state.allDataSources, type)]);
  }, [state.allDataSources, type]);

  const handleOnChange = (selections) => {
    if (!_.isEqual(selected, selections)) {
      setSelected(selections);
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_PROGRAMS,
        payload: {
          type: type,
          index: index,
          value: selections.map((selection) => selection.value),
        },
      });
    }
  };

  return (
    <>
      <MultiSelect
        options={programOptions}
        value={selected}
        type={type}
        sourceType='program'
        menuStyleWidth='275px'
        onChange={handleOnChange}
        sticky={false}
        includeSelectDeselectFooter={true}
        searchable={true}
      />
    </>
  );
}
