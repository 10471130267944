import React, { useState, useEffect, useRef } from 'react';
import { CheckPicker } from 'rsuite';
import _ from 'lodash';

import { dropdownSearchActions, useDropdownSearchContext } from '../context/stateManager';

export default function SelectHemisphere({ type, index }) {
  const picker = useRef();
  const { state, dispatch } = useDropdownSearchContext();
  const [value, setValue] = useState([]);
  const [appliedValue, setAppliedValue] = useState(value);

  const options = [
    { label: 'Northern', value: 'northern' },
    { label: 'Southern', value: 'southern' },
  ];

  // Required to ensure the criteria is updated when a new example is loaded in
  useEffect(() => {
    if (type === "space" && state.queryObject[type][index].hemisphere && state.queryObject[type][index].hemisphere.length !== 0) {
      setValue(state.queryObject[type][index].hemisphere);
    } else {
      setValue([]); // Ensures selector is cleared on reset
    }
  }, [state.queryObject[type][index]]);

  const onChange = (selected) => {
    setValue(selected);
    setAppliedValue(selected);
  };

  const handleApply = () => {
    setAppliedValue(value);
  };

  // TODO: this is firing too many times
  useEffect(() => {
    if (!_.isEqual(state.queryObject.conjunction_types, appliedValue) && type === "space") {
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_HEMISPHERES,
        payload: {
          type: type,
          index: index,
          value: appliedValue,
        },
      });
    }
  }, [appliedValue]);

  return (
    <>
      {type === 'space'
        && (
          <>
            <CheckPicker
              id="hemisphere"
              placeholder="Select values"
              data={options}
              value={value}
              ref={picker}
              onChange={onChange}
              onClose={handleApply}
              style={{ width: '128px' }}
              searchable={false}
              cleanable={false}
              sticky={false}
            />
          </>
        )}

      {type === 'ground'
        && (
          <>
            &ndash;
          </>
        )}

      {type === 'events'
        && (
          <>
            &ndash;
          </>
        )}
    </>
  );
}
