// Importing Components from node_modules
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { CheckPicker } from 'rsuite';

// Importing styling
import 'rsuite/dist/rsuite.min.css';

const MultiSelect = (props) => {
  const picker = useRef();
  const [value, setValue] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [allValues, setAllValues] = useState(props.options.map((option) => option.value));
  const [disabled, setDisabled] = useState(false);
  const [placeholder, setPlaceholder] = useState('Select values');
  const [selectAllText, setSelectAllText] = useState('Select all');
  const [deselectAllText, setDeselectAllText] = useState('Deselect all');

  // Styling for checkpicker
  const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5',
    display: 'flex',
    justifyContent: 'space-around',
  };

  /*
    The value used by the dropdown is a different format than that used in context. So the
    following functions updates both values appropriately

    Function for when a single option is selected or deselected
  */
  const onChange = useCallback((selected) => {
    let updatedSelect;

    //  Needed in order to remove footprint from the query if another option has been selected
    if (props.sourceType === 'instrument' && props.type === 'space'
      && selected.filter((x) => x !== 'footprint').length > 0
    ) {
      updatedSelect = selected.filter((x) => x !== 'footprint');
      // If nothing is selected, default to footprint for query value
    } else if (props.sourceType === 'instrument' && props.type === 'space'
      && selected.filter((x) => x !== 'footprint').length === 0
    ) {
      updatedSelect = ['footprint'];
    } else {
      updatedSelect = selected;
    }

    setValue(updatedSelect);
    const newValue = updatedSelect.map((x) => ({ value: x, label: x }));
    props.onChange(newValue || []);
  }, [props, setValue]);

  const onSearch = useCallback((newSearchKeyword) => {
    setSearchKeyword(newSearchKeyword.toLowerCase());
    const newAllValues = props.options.filter((option) => {
      return option.value.toLowerCase().includes(newSearchKeyword);
    }).map((option) => {
      return option.value;
    });
    setAllValues(newAllValues);

    // set select/deselect all text
    if (newAllValues.length === props.options.length) {
      setSelectAllText('Select all');
      setDeselectAllText('Deselect all');
    } else {
      setSelectAllText('Select all (' + newAllValues.length + ')');
      setDeselectAllText('Deselect all (' + newAllValues.length + ')');
    }
  }, [props.value, props.options, setSelectAllText, setDeselectAllText, setAllValues, setSearchKeyword]);

  const onClose = useCallback(() => {
    setSelectAllText('Select all');
    setDeselectAllText('Deselect all');
    setSearchKeyword('');
  }, [setSelectAllText, setDeselectAllText]);

  useEffect(() => {
    if (props.sourceType === 'instrument'
      && props.type === 'space'
      && props.options.length === 0
    ) {
      /*
        Following  will ensure that if there is no space instrument available
        then footprint will be "selected" and the checkpicker will disable itself
      */
      setPlaceholder('Spacecraft');
      setDisabled(true);
      props.onChange(['footprint'].map((x) => ({ value: x, label: x })));
    } else if (props.sourceType === 'instrument'
      && props.type === 'space'
      && props.options.length > 0
    ) {
      setPlaceholder('Spacecraft');
      setDisabled(false);

      if (props.value.length > 0) {
        onChange(value.filter((x) => allValues.includes(x)));
      } else if (props.value.length === 0) {
        /*
          Following logic is meant to set the query to footprint if no other option that is
          available in the dropdown has been selected
        */
        props.onChange(['footprint'].map((x) => ({ value: x, label: x })));
      }
    } else if (props.sourceType === 'instrument'
      && props.type === 'ground'
      && props.options.length === 1
    ) {
      /*
        Following will ensure that if there is only one ground instrument available
        then said instrument would be selected and the checkpicker will disable itself
      */
      props.onChange(props.options);
      setDisabled(true);
    } else {
      /*
        Following will ensure that a previously selected option that has been hidden by the
        filter, will not reappear as selected when the filter is removed

        Needs to be called when props.changeFlag changes because otherwise the reset buttons will
        not function correctly
      */
      onChange(value.filter((x) => allValues.includes(x)));
      setDisabled(false);
    }
  }, [props.changeFlag]);

  /*
    Following is used when the props.value has a change. The main purpose for it is to update the
    selected values of the dropdown to match the query string of an example.

    It also resolves the issue with the reset functions
  */
  useEffect(() => {
    setValue(props.value.map((x) => x.value));
  }, [props.value]);

  useEffect(() => {
    setAllValues(props.options.map((option) => option.value));
  }, [props.options]);

  useEffect(() => {
    if (disabled === false && props.sourceType === 'instrument' && props.type === 'ground') {
      onChange([]);
    }
  }, [disabled]);

  const handleCheckAll = useCallback((e) => {
    e.target.blur();
    let newValues = props.options.map((option) => option.value); // base case, if none are selected, this says all will be selected
    if (searchKeyword.length > 0) {
      // some things are selected already and there's a search keyword, check to see if they match it. If they do, then add
      newValues = props.options.filter((option) => {
        if (option.value.toLowerCase().includes(searchKeyword)) {
          // search keyword matches
          return true;
        } else {
          return false;
        }
      }).map((option) => option.value);

      // add in existing selected items
      newValues = newValues.concat(props.value.map((option) => option.value));
    }
    onChange(newValues);
  }, [props.value, props.options, searchKeyword, onChange]);

  const handleUncheckAll = useCallback((e) => {
    e.target.blur();
    const newValues = props.value.filter((option) => {
      return !option.value.toLowerCase().includes(searchKeyword);
    }).map((option) => {
      return option.value;
    });
    onChange(newValues);
  }, [props.value, searchKeyword, onChange]);

  return (
    <div className="dropdown-page--select">
      <CheckPicker
        placeholder={placeholder}
        data={props.options}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onClose={onClose}
        ref={picker}
        placement={'autoVerticalStart'}
        sticky={props.sticky}
        style={{ width: props.width || '180px' }}
        menuStyle={{ zIndex: 1050, width: props.menuStyleWidth || 'auto' }}
        searchable={props.searchable}
        menuMaxHeight={window.innerHeight * 0.5 - 120}
        onSearch={onSearch}
        renderExtraFooter={function () {
          if (props.includeSelectDeselectFooter == true) {
            // return
            return (
              <div style={footerStyles}>
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={handleCheckAll}
                  style={{ width: "47%" }}
                >
                  {selectAllText}
                </Button>
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={handleUncheckAll}
                  style={{ width: "47%" }}
                >
                  {deselectAllText}
                </Button>
              </div>
            );
          } else {
            return;
          }
        }
        }
      />
    </div>
  );
};

export default MultiSelect;
