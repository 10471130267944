export const SWARM_AURORA_TRANSLATIONS = {
    "arase, arase, footprint": { "instrument": "arase", "site": "ssc" },
    "cluster, cluster1, footprint": { "instrument": "cluster1", "site": "ssc" },
    "dmsp, dmsp06, footprint": { "instrument": "dmspf06", "site": "ssc" },
    "dmsp, dmsp07, footprint": { "instrument": "dmspf07", "site": "ssc" },
    "dmsp, dmsp08, footprint": { "instrument": "dmspf08", "site": "ssc" },
    "dmsp, dmsp09, footprint": { "instrument": "dmspf09", "site": "ssc" },
    "dmsp, dmsp10, footprint": { "instrument": "dmspf10", "site": "ssc" },
    "dmsp, dmsp11, footprint": { "instrument": "dmspf11", "site": "ssc" },
    "dmsp, dmsp12, footprint": { "instrument": "dmspf12", "site": "ssc" },
    "dmsp, dmsp13, footprint": { "instrument": "dmspf13", "site": "ssc" },
    "dmsp, dmsp14, footprint": { "instrument": "dmspf14", "site": "ssc" },
    "dmsp, dmsp15, footprint": { "instrument": "dmspf15", "site": "ssc" },
    "dmsp, dmsp16, footprint": { "instrument": "dmspf16", "site": "ssc" },
    "dmsp, dmsp17, footprint": { "instrument": "dmspf17", "site": "ssc" },
    "dmsp, dmsp18, footprint": { "instrument": "dmspf18", "site": "ssc" },
    "dmsp, dmsp19, footprint": { "instrument": "dmspf19", "site": "ssc" },
    "elfin, elfina, footprint": { "instrument": "elfina", "site": "ssc" },
    "elfin, elfinb, footprint": { "instrument": "elfinb", "site": "ssc" },
    "epop, epop, footprint": { "instrument": "cassiope", "site": "ssc" },
    "epop, epop, CER": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, FAI": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, GAP": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, IRM": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, MGF": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, NMS": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, RRI": { "instrument": "cassiope", "site": "instrument_ops" },
    "epop, epop, SEI": { "instrument": "cassiope", "site": "instrument_ops" },
    "fast, fast, footprint": { "instrument": "fast", "site": "ssc" },
    "goes, goes8, footprint": { "instrument": "goes8", "site": "ssc" },
    "goes, goes9, footprint": { "instrument": "goes9", "site": "ssc" },
    "goes, goes10, footprint": { "instrument": "goes10", "site": "ssc" },
    "goes, goes12, footprint": { "instrument": "goes12", "site": "ssc" },
    "goes, goes16, footprint": { "instrument": "goes16", "site": "ssc" },
    "goes, goes17, footprint": { "instrument": "goes17", "site": "ssc" },
    "goes, goes18, footprint": { "instrument": "goes18", "site": "ssc" },
    "mms, mms1, footprint": { "instrument": "mms1", "site": "ssc" },
    "noaa, noaa8, footprint": { "instrument": "noaa8", "site": "ssc" },
    "noaa, noaa10, footprint": { "instrument": "noaa10", "site": "ssc" },
    "noaa, noaa12, footprint": { "instrument": "noaa12", "site": "ssc" },
    "noaa, noaa14, footprint": { "instrument": "noaa14", "site": "ssc" },
    "noaa, noaa15, footprint": { "instrument": "noaa15", "site": "ssc" },
    "noaa, noaa16, footprint": { "instrument": "noaa16", "site": "ssc" },
    "noaa, noaa17, footprint": { "instrument": "noaa17", "site": "ssc" },
    "noaa, noaa18, footprint": { "instrument": "noaa18", "site": "ssc" },
    "noaa, noaa19, footprint": { "instrument": "noaa19", "site": "ssc" },
    "noaa, noaa20, footprint": { "instrument": "noaa20", "site": "ssc" },
    "rainbow, athabasca, RGB ASI": { "instrument": "asi_rainbow", "site": "atha", "map": "north_america" },
    "rainbow, fort simpson, RGB ASI": { "instrument": "asi_rainbow", "site": "fsim", "map": "north_america" },
    "rainbow, fort smith, RGB ASI": { "instrument": "asi_rainbow", "site": "fsmi", "map": "north_america" },
    "rainbow, gillam, RGB ASI": { "instrument": "asi_rainbow", "site": "gill", "map": "north_america" },
    "rainbow, pinawa, RGB ASI": { "instrument": "asi_rainbow", "site": "pina", "map": "north_america" },
    "rainbow, rabbit lake, RGB ASI": { "instrument": "asi_rainbow", "site": "rabb", "map": "north_america" },
    "rego, athabasca, redline ASI": { "instrument": "asi_rego", "site": "atha", "map": "north_america" },
    "rego, fort simpson, redline ASI": { "instrument": "asi_rego", "site": "fsim", "map": "north_america" },
    "rego, fort smith, redline ASI": { "instrument": "asi_rego", "site": "fsmi", "map": "north_america" },
    "rego, gillam, redline ASI": { "instrument": "asi_rego", "site": "gill", "map": "north_america" },
    "rego, kaktovik, redline ASI": { "instrument": "asi_rego", "site": "kakt", "map": "north_america" },
    "rego, longyearbyen, redline ASI": { "instrument": "asi_rego", "site": "lyrn", "map": "scandinavia" },
    "rego, lucky lake, redline ASI": { "instrument": "asi_rego", "site": "luck", "map": "north_america" },
    "rego, rankin inlet, redline ASI": { "instrument": "asi_rego", "site": "rank", "map": "north_america" },
    "rego, resolute bay, redline ASI": { "instrument": "asi_rego", "site": "resu", "map": "north_america" },
    "rego, sachs harbour, redline ASI": { "instrument": "asi_rego", "site": "sach", "map": "north_america" },
    "rego, taloyoak, redline ASI": { "instrument": "asi_rego", "site": "talo", "map": "north_america" },
    "rbsp, rbspa, footprint": { "instrument": "rbspa", "site": "ssc" },
    "rbsp, rbspb, footprint": { "instrument": "rbspb", "site": "ssc" },
    "sampex, sampex, footprint": { "instrument": "sampex", "site": "ssc" },
    "swarm, swarma, footprint": { "instrument": "swarma", "site": "ssc" },
    "swarm, swarma, thermal ion imager": { "instrument": "swarma", "site": "tii" },
    "swarm, swarmb, footprint": { "instrument": "swarmb", "site": "ssc" },
    "swarm, swarmb, thermal ion imager": { "instrument": "swarmb", "site": "tii" },
    "swarm, swarmc, footprint": { "instrument": "swarmc", "site": "ssc" },
    "swarm, swarmc, thermal ion imager": { "instrument": "swarmc", "site": "tii" },
    "themis, themisa, footprint": { "instrument": "themisa", "site": "ssc" },
    "themis, themisb, footprint": { "instrument": "themisb", "site": "ssc" },
    "themis, themisc, footprint": { "instrument": "themisc", "site": "ssc" },
    "themis, themisd, footprint": { "instrument": "themisd", "site": "ssc" },
    "themis, themise, footprint": { "instrument": "themise", "site": "ssc" },
    "themis-asi, athabasca, panchromatic ASI": { "instrument": "asi_themis", "site": "atha", "map": "north_america" },
    "themis-asi, chibougamau, panchromatic ASI": { "instrument": "asi_themis", "site": "chbg", "map": "north_america" },
    "themis-asi, ekati, panchromatic ASI": { "instrument": "asi_themis", "site": "ekat", "map": "north_america" },
    "themis-asi, fort simpson, panchromatic ASI": { "instrument": "asi_themis", "site": "fsim", "map": "north_america" },
    "themis-asi, fort smith, panchromatic ASI": { "instrument": "asi_themis", "site": "fsmi", "map": "north_america" },
    "themis-asi, fort yukon, panchromatic ASI": { "instrument": "asi_themis", "site": "fykn", "map": "north_america" },
    "themis-asi, gakona, panchromatic ASI": { "instrument": "asi_themis", "site": "gako", "map": "north_america" },
    "themis-asi, gillam, panchromatic ASI": { "instrument": "asi_themis", "site": "gill", "map": "north_america" },
    "themis-asi, goose bay, panchromatic ASI": { "instrument": "asi_themis", "site": "gbay", "map": "north_america" },
    "themis-asi, inuvik, panchromatic ASI": { "instrument": "asi_themis", "site": "inuv", "map": "north_america" },
    "themis-asi, kapuskasing, panchromatic ASI": { "instrument": "asi_themis", "site": "kapu", "map": "north_america" },
    "themis-asi, kiana, panchromatic ASI": { "instrument": "asi_themis", "site": "kian", "map": "north_america" },
    "themis-asi, kuujjuaq, panchromatic ASI": { "instrument": "asi_themis", "site": "kuuj", "map": "north_america" },
    "themis-asi, mcgrath, panchromatic ASI": { "instrument": "asi_themis", "site": "mcgr", "map": "north_america" },
    "themis-asi, narsarsuaq, panchromatic ASI": { "instrument": "asi_themis", "site": "nrsq", "map": "north_america" },
    "themis-asi, pinawa, panchromatic ASI": { "instrument": "asi_themis", "site": "pina", "map": "north_america" },
    "themis-asi, prince george, panchromatic ASI": { "instrument": "asi_themis", "site": "pgeo", "map": "north_america" },
    "themis-asi, rankin inlet, panchromatic ASI": { "instrument": "asi_themis", "site": "rank", "map": "north_america" },
    "themis-asi, sanikiluaq, panchromatic ASI": { "instrument": "asi_themis", "site": "snkq", "map": "north_america" },
    "themis-asi, snap lake, panchromatic ASI": { "instrument": "asi_themis", "site": "snap", "map": "north_america" },
    "themis-asi, taloyoak, panchromatic ASI": { "instrument": "asi_themis", "site": "talo", "map": "north_america" },
    "themis-asi, the pas, panchromatic ASI": { "instrument": "asi_themis", "site": "tpas", "map": "north_america" },
    "themis-asi, whitehorse, panchromatic ASI": { "instrument": "asi_themis", "site": "whit", "map": "north_america" },
    "themis-asi, yellowknife, panchromatic ASI": { "instrument": "asi_themis", "site": "yknf", "map": "north_america" },
    "trex, athabasca, blueline ASI": { "instrument": "asi_trex_blue", "site": "atha", "map": "north_america" },
    "trex, fort smith, blueline ASI": { "instrument": "asi_trex_blue", "site": "fsmi", "map": "north_america" },
    "trex, gillam, blueline ASI": { "instrument": "asi_trex_blue", "site": "gill", "map": "north_america" },
    "trex, lucky lake, blueline ASI": { "instrument": "asi_trex_blue", "site": "luck", "map": "north_america" },
    "trex, pinawa, blueline ASI": { "instrument": "asi_trex_blue", "site": "pina", "map": "north_america" },
    "trex, rabbit lake, blueline ASI": { "instrument": "asi_trex_blue", "site": "rabb", "map": "north_america" },
    "trex, athabasca, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "atha", "map": "north_america" },
    "trex, fort smith, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "fsmi", "map": "north_america" },
    "trex, gillam, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "gill", "map": "north_america" },
    "trex, lucky lake, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "luck", "map": "north_america" },
    "trex, pinawa, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "pina", "map": "north_america" },
    "trex, rabbit lake, near-infrared ASI": { "instrument": "asi_trex_nir", "site": "rabb", "map": "north_america" },
    "trex, athabasca, RGB ASI": { "instrument": "asi_trex_rgb", "site": "atha", "map": "north_america" },
    "trex, fort smith, RGB ASI": { "instrument": "asi_trex_rgb", "site": "fsmi", "map": "north_america" },
    "trex, gillam, RGB ASI": { "instrument": "asi_trex_rgb", "site": "gill", "map": "north_america" },
    "trex, lucky lake, RGB ASI": { "instrument": "asi_trex_rgb", "site": "luck", "map": "north_america" },
    "trex, pinawa, RGB ASI": { "instrument": "asi_trex_rgb", "site": "pina", "map": "north_america" },
    "trex, rabbit lake, RGB ASI": { "instrument": "asi_trex_rgb", "site": "rabb", "map": "north_america" },
    "trex, lucky lake, spectrograph": { "instrument": "asi_trex_spect", "site": "luck", "map": "north_america" },
    "trex, rabbit lake, spectrograph": { "instrument": "asi_trex_spect", "site": "rabb", "map": "north_america" },
};
