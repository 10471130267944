// Importing Components from node_modules
import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faDownload } from "@fortawesome/pro-regular-svg-icons";

// Importing project-defined components
import { useDropdownSearchContext } from "./context/stateManager";
import { Error, Success } from "../components/Toasts";
import { deployment_type as deploymentType } from "../../../deployment";

// set Swarm-Aurora URL staging flag
let url = "";
if (deploymentType === "staging" || deploymentType === "development") {
  url = "&staging=true";
}

// set max conjunction count for interacting with Swarm-Aurora
const maxConjunctionCount = 5000;

const SwarmAuroraTools = () => {
  return (
    <>
      <div>
        <SwarmAuroraOpen />
      </div>
      <div>
        <SwarmAuroraGenerateCustomImportFile />
      </div>
    </>
  );
};

const SwarmAuroraOpen = () => {
  const { state } = useDropdownSearchContext();

  function openInSwarmAurora() {
    // check if there's too many conjunction results
    //
    // note: we"ve found that >10k is a problem for Swarm-Aurora
    // as it takes too long to retrieve from AuroraX and process
    // them, eventually timing out. Refer to #88 in the
    // Swarm-Aurora webapp repository for details.
    //
    // https://git.phys.ucalgary.ca/swarmaurora/webapp/-/issues/88
    let foundConjunctions = state.request.search_result.result_count;
    if (foundConjunctions !== "undefined" && foundConjunctions >= maxConjunctionCount) {
      // too many results, show a toast instead
      Error("Sorry, Swarm-Aurora can't load this many conjunctions." +
        " Please adjust your search parameters to find <" + maxConjunctionCount +
        " conjunctions and try again.");
    } else {
      window.open(`https://swarm-aurora.com/conjunctionFinder?aurorax_request_id=${state.queryID}${url}`, "_blank");
    }
  }

  return (
    <>
      <Button
        variant="outline-dark"
        size="sm"
        disabled={state.queryID === null || state.searching}
        onClick={openInSwarmAurora}
        className="mr-1"
      >
        Open All Results
        <FontAwesomeIcon icon={faExternalLink} fixedWidth className="swarm-tool-icons" />
      </Button>
    </>
  );
};

const SwarmAuroraGenerateCustomImportFile = () => {
  const { state } = useDropdownSearchContext();

  function downloadFile() {
    // check if there"s too many conjunction results
    //
    // note: we"ve found that >10k is a problem for Swarm-Aurora
    // as it takes too long to retrieve from AuroraX and process
    // them, eventually timing out. Refer to #88 in the
    // Swarm-Aurora webapp repository for details.
    //
    // https://git.phys.ucalgary.ca/swarmaurora/webapp/-/issues/88
    let foundConjunctions = state.request.search_result.result_count;
    if (foundConjunctions !== "undefined" && foundConjunctions >= maxConjunctionCount) {
      // too many results, show a toast instead
      Error("Sorry, we can't generate a Swarm-Aurora import file for" +
        " this many conjunctions. Please adjust your search parameters" +
        " to find <" + maxConjunctionCount + " conjunctions and try again.");
      return;
    }

    // set button to be a spinner and disabled
    let download_btn = document.getElementById("swarmaurora-custom-import-download-btn");
    download_btn.disabled = true;
    download_btn.style.width = download_btn.offsetWidth + "px";
    let current_download_btn_innerHTML = download_btn.innerHTML;
    download_btn.innerHTML = "<span>Generating file ...</span>";

    // get file content from Swarm-Aurora
    setTimeout(function () {
      fetch(`https://swarm-aurora.com/conjunctionFinder/generate_custom_import_json?aurorax_request_id=${state.queryID}${url}`)
        .then(response => response.json())
        .then(body => {
          // format the output
          body = JSON.stringify(body, null, 4); // 4 spaces for indents

          // download data as a file
          let blob = new Blob([body], { type: "application/json" });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `swarmaurora-custom-import-${state.queryID}.json`;
          a.click();
          window.URL.revokeObjectURL(url);

          // show a toast
          Success("Custom import file downloaded");

          // re-enable button
          download_btn.innerHTML = current_download_btn_innerHTML;
          download_btn.disabled = false;
          download_btn.style.width = null;
        })
        .catch(error => {
          // show a toast
          Error("Problem generating custom import file: " + error);

          // re-enable button
          download_btn.innerHTML = current_download_btn_innerHTML;
          download_btn.disabled = false;
          download_btn.style.width = null;
        });
    }, 1000);
  }

  return (
    <>
      <Button
        variant="outline-dark"
        size="sm"
        disabled={state.queryID === null || state.searching}
        onClick={downloadFile}
        id="swarmaurora-custom-import-download-btn"
      >
        Custom Import File
        <FontAwesomeIcon icon={faDownload} fixedWidth className="swarm-tool-icons" />
      </Button>
    </>
  );
};


export default SwarmAuroraTools;