import React from "react";
import { Navigate } from "react-router-dom";

// Routes that require authentication to access
export const AuthRoute = (props) => {
  const authSession = sessionStorage.getItem('auth');

  if (authSession) {
    return props.children;
  } else {
    return <Navigate to="/login" replace />;
  }
  // return (
  //   <Route
  //     {...rest}
  //     render={props => {
  //       if (authSession) {
  //         return <Component {...props} />;
  //       } else {
  //         return (<Redirect to="/login" />);
  //       }
  //     }}
  //   />
  // );
};

// Routes that authenticated users should not be able to access
export const UnauthRoute = (props) => {
  const authSession = sessionStorage.getItem('auth');

  if (!authSession) {
    return props.children;
  } else {
    return <Navigate to="/" replace />;
  }

  // return (
  //   <Route
  //     {...rest}
  //     render={props => {
  //       if (!authSession) {
  //         return <Component {...props} />;
  //       } else {
  //         return (<Redirect to="/" />);
  //       }
  //     }}
  //   />
  // );
};

// Routes that only Desktop users can access
// export const DesktopRoute = ({
//   component: Component,
//   ...rest
// }) => {
//   return (

//     <Route
//       {...rest}
//       render={props => {
//         return <Component {...props} />;
//       }}
//     />
//   );
// };
