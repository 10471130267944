/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import { FormControl, Form, FormLabel, FormGroup } from 'react-bootstrap';
import { Row, Alert as BootstrapAlert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus, faPencil, faPlus, faUndo } from '@fortawesome/pro-regular-svg-icons';

import MultiSelect from './MultiSelect';
import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';

export default function MetadataFilters({ filterBySourceType, type, index }) {
  const { state } = useDropdownSearchContext();
  const [label, setLabel] = useState('None');
  const [show, setShow] = useState(false);

  // Used to open the modal
  const handleShow = () => setShow(true);

  // Following updates the textbox to state how many filters have been added to the query, if any.
  useEffect(() => {
    if (state.queryObject[type][index].ephemeris_metadata_filters
      && state.queryObject[type][index].ephemeris_metadata_filters.expressions
      && state.queryObject[type][index].ephemeris_metadata_filters.expressions.length > 0) {
      setLabel(`${state.queryObject[type][index].ephemeris_metadata_filters.expressions.length} added`);
    } else {
      setLabel('None');
    }
  }, [state.queryObject[type][index].ephemeris_metadata_filters]);

  return (
    <>
      <InputGroup>
        <FormControl
          aria-label="Number of filters"
          value={label}
          size="sm"
          disabled
        />
        <InputGroup.Append>
          <Button variant="outline-dark" size="sm" onClick={handleShow}>
            {/* If there are no filters, show "None" and have plus icon appear*/}
            {
              ((!state.queryObject
                || !state.queryObject[type][index].ephemeris_metadata_filters
                || !state.queryObject[type][index].ephemeris_metadata_filters.expressions)
                || state.queryObject[type][index].ephemeris_metadata_filters.expressions.length === 0
              )
              && <FontAwesomeIcon icon={faPlus} fixedWidth size="sm" />
            }
            {/* If there are metadata filters, show the count of how many & have edit icon appear */}
            {
              (state.queryObject
                && state.queryObject[type][index].ephemeris_metadata_filters
                && state.queryObject[type][index].ephemeris_metadata_filters.expressions
                && state.queryObject[type][index].ephemeris_metadata_filters.expressions.length > 0
              )
              && <FontAwesomeIcon icon={faPencil} fixedWidth size="sm" />
            }
          </Button>
        </InputGroup.Append>
      </InputGroup>

      <MetadataFilterModal
        show={show}
        setShow={setShow}
        filterBySourceType={filterBySourceType}
        type={type}
        index={index}
      />
    </>
  );
}

function MetadataFilterModal({ show, setShow, filterBySourceType, type, index }) {
  const [logicalOperator, setLogicalOperator] = useState("AND");
  const { state, dispatch } = useDropdownSearchContext();
  const [commonMetadata, setCommonMetadata] = useState([]);

  // Used to close the modal
  function handleClose() {
    setShow(false);
    document.querySelector('body').classList.remove('modal-open');
  }

  // When the user clicks the OK button on metadata modal
  function handleOk() {
    // map the common metadata fields with values set back into the criteria object.
    let newExpressions = commonMetadata
      .filter((metadataField) => metadataField.values.length > 0)
      .map((metadataField) => (
        {
          key: metadataField.definition.field_name,
          operator: metadataField.selectedOperator,
          values: metadataField.values,
        }
      ));

    if (!state.queryObject[type][index].ephemeris_metadata_filters.expressions || !_.isEqual(state.queryObject[type][index].ephemeris_metadata_filters.expressions, newExpressions)) {
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_METADATA_FILTERS,
        payload: {
          type: type,
          index: index,
          value: {
            logical_operator: logicalOperator,
            expressions: newExpressions,
          },
        },
      });
    }
    handleClose();
  }

  /*
   * For every schema entry of every data source that matches the criteria, if every matching data source also has a
   * schema entry with the same field name, add that schema entry to commonMetadata
   */
  function parseDataSources() {
    // if there is no data loaded, or if criteria is empty
    if (!state.allDataSources) {
      return [];
    }

    let matchingDataSources = [...state.allDataSources];

    // criteria.key: dataSource.key
    let criteriaBlocks = {
      programs: "program",
      platforms: "platform",
      instrument_types: "instrument_type",
    };

    let resultingMetadata = {};

    // filter data sources by source type so we only show applicable filter options
    if (filterBySourceType) {
      matchingDataSources = matchingDataSources.filter(
        dataSource => dataSource.source_type === filterBySourceType || filterBySourceType.includes(dataSource.source_type),
      );
    }

    // filter the data sources to get the ones that satisfy all criteria
    for (let [criteriaKey, dataSourceKey] of Object.entries(criteriaBlocks)) {
      // if empty, continue
      if (!state.queryObject[type][index][criteriaKey].length || state.queryObject[type][index][criteriaKey][0] === "") { continue; }// eslint-disable-line no-continue, brace-style

      matchingDataSources = matchingDataSources.filter(dataSource => state.queryObject[type][index][criteriaKey].includes(dataSource[dataSourceKey]));
    }

    // if there aren't any, we're done and we return an empty metadata options array
    if (!matchingDataSources.length) { return []; }   // eslint-disable-line no-continue

    for (let dataSource of matchingDataSources) {
      for (let metadata of dataSource.ephemeris_metadata_schema) {
        resultingMetadata[metadata.field_name] = {
          definition: metadata,
          selectedOperator: '=',
          values: [],
        };
      }
    }

    // resultingMetadata now has everything we need
    return Object.values(resultingMetadata);
  }

  // When a new criteria is selected update the list of potential filters
  useEffect(() => {
    let metadataOptions = parseDataSources(type);
    setCommonMetadata(metadataOptions);
  }, [state.queryObject[type][index].programs, state.queryObject[type][index].platforms, state.queryObject[type][index].instrument_types]);

  useEffect(() => {
    if (!commonMetadata.length && Object.keys(state.queryObject[type][index].ephemeris_metadata_filters).length !== 0) {
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_METADATA_FILTERS,
        payload: {
          type: type,
          index: index,
          value: {},
        },
      });
    } else {
      // we need to check that any metadata filters that are set haven't been made inapplicable
      // by the change in search criteria. E.g. if "tii_on" is set while searching for Swarm instruments,
      // but we change the criteria to no longer include any Swarm instruments, that "tii_on" metadata filter
      // should be removed because it's no longer applicable to the search criteria
      if (state.queryObject[type][index].ephemeris_metadata_filters
        && state.queryObject[type][index].ephemeris_metadata_filters.expressions
        && state.queryObject[type][index].ephemeris_metadata_filters.expressions.length > 0) {

        // go over every metadata filter that's currently set and, if the key matches any of the
        // newly gathered common filters (based on the new criteria), push it to
        // new_criteria_expressions so we know to keep it
        let new_criteria_expressions = [];
        for (let currentCriteriaFilter of state.queryObject[type][index].ephemeris_metadata_filters.expressions) {
          let checkForValidFilter = commonMetadata.filter(commonFilter => {
            return commonFilter.definition.field_name === currentCriteriaFilter.key;
          });

          if (checkForValidFilter.some(v => v)) {
            new_criteria_expressions.push(currentCriteriaFilter);
          }
        }

        // set the criteria's ephemeris_metadata_fields to the items that we've just determined
        // to be valid in the new_criteria_expressions list, or set it to {} if there are no
        // longer any applicable filters carried over from the previous criteria settings
        if (!_.isEqual(state.queryObject[type][index].ephemeris_metadata_filters.expressions, new_criteria_expressions)) {
          dispatch({
            type: dropdownSearchActions.SET_CRITERIA_METADATA_FILTERS,
            payload: {
              type: type,
              index: index,
              value: {
                logical_operator: logicalOperator,
                expressions: new_criteria_expressions,
              },
            },
          });
        }
      }
    }
  }, [commonMetadata]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleOk}
        size="lg"
        className="modal--metadata-filter"
        scrollable={true}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Metadata Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "14px" }}>
            Conjunction searching can be further enhanced by using &#34;Metadata Filters&#34;. These filters
            apply to any metadata contained in AuroraX &#34;ephemeris&#34; records. The available metadata
            filters shown below are ones consistent across all program/platform/instrument-type values
            currently selected. Note: the &#34;between&#34; operator must be given two values separated
            by a comma.
          </p>
          <p className="mb-4" style={{ fontSize: "14px" }}>
            Each row of metadata can be evaluated as a logical "AND" or "OR" statement. You may
            select which is used with the dropdown below. If a row has a dropdown with multiple
            values selected, these are evaluated as logical "OR" statements.
          </p>

          {commonMetadata && commonMetadata.length === 0 &&
            <BootstrapAlert
              className="dev-alert alert--lightgreen"
              dismissible={false}
              style={{ borderRadius: "5px" }}
            >
              <p>
                No metadata filters are available for the selected instruments
              </p>
            </BootstrapAlert>
          }


          {commonMetadata && commonMetadata.length !== 0 && (
            <>
              <FormGroup as={Row} className="mb-0">
                <FormLabel style={{ fontSize: "14px", marginRight: "10px", marginLeft: "15px", paddingTop: "3px" }}>
                  Logical Operator
                </FormLabel>
                <Form.Control
                  size="sm"
                  as="select"
                  className="logical-operator-selector"
                  defaultValue={state.queryObject[type][index].ephemeris_metadata_filters && state.queryObject[type][index].ephemeris_metadata_filters.logical_operator ? state.queryObject[type][index].ephemeris_metadata_filters.logical_operator : "AND"}
                  onChange={e => setLogicalOperator(e.target.value)}
                >
                  {
                    (["AND", "OR"])
                      .map(theOperator => (
                        <option key={theOperator} value={theOperator}>{theOperator}</option>
                      ))
                  }
                </Form.Control>
              </FormGroup>

              <table className="table table-compact table-sm table-borderless dropdown-metadata-modal-table">
                <thead className="thead-light">
                  <tr>
                    <th>Metadata Field</th>
                    <th>Operator</th>
                    <th>Filter value(s)</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Following cycles through all the metadata filter names */}
                  {
                    commonMetadata
                    && commonMetadata.map((metadataSchemaField) => {
                      return (

                        <MetadataField
                          show={show}
                          schemaField={{ metadataSchemaField }}
                          key={`${type}-${index}-${metadataSchemaField.definition.field_name}`}
                          type={type}
                          index={index}
                        />
                      );
                    },
                    )
                  }
                </tbody>
              </table>
            </>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>Cancel</Button>
          <Button variant="outline-dark" onClick={handleOk}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function MetadataField({ show, schemaField, type, index }) {
  // figure out what type this is
  const isList = schemaField.metadataSchemaField.definition.allowed_values
    && schemaField.metadataSchemaField.definition.allowed_values.length > 0;

  // init state
  let initialOperator = '=';
  if (isList) {
    initialOperator = 'in';
  }
  const [value, setValue] = useState('');
  const [operator, setOperator] = useState(initialOperator);
  const [selected, setSelected] = useState([]);
  const [showDescription, setShowDescription] = useState(false);

  const { state } = useDropdownSearchContext();
  const [metadataField, setMetadataField] = useState(schemaField.metadataSchemaField);

  /*
    Needed the following in order to ensure that the rsuite checkpicker will update with the correctly
    selected values when the modal is opened
  */
  useEffect(() => {
    if (show === true) {
      // if the expressions key exists
      if (state.queryObject[type][index].ephemeris_metadata_filters && state.queryObject[type][index].ephemeris_metadata_filters.expressions) {
        // check if expressions is an empty list
        if (state.queryObject[type][index].ephemeris_metadata_filters.expressions.length > 0) {
          // go through each expression
          for (let elem of state.queryObject[type][index].ephemeris_metadata_filters.expressions) {
            if (elem.key === metadataField.definition.field_name) {
              handleOnSelectValues(elem.values.map((x) => ({ value: x, label: x })));
            }
          }
        }
      } else {
        // no expression key exists, starting from fresh load (or from fresh reset of the criteria block)
        setValue("");
        setOperator("=");
        metadataField.selectedOperator = "=";
        metadataField.values = [];
        setMetadataField(metadataField);
      }
    }
  }, [show]);

  useEffect(() => {
    if (isList && metadataField.values) {
      setSelected(metadataField.values.map((previousValue) => ({
        label: previousValue,
        value: previousValue,
      })));
    } else if (metadataField.values) {
      setValue(metadataField.values.join(','));
      setOperator(metadataField.operator);
    }
  }, [metadataField.values]);

  useEffect(() => {
    let thisOperator;
    if (state.queryObject[type][index].ephemeris_metadata_filters && state.queryObject[type][index].ephemeris_metadata_filters.expressions) {
      for (let x of state.queryObject[type][index].ephemeris_metadata_filters.expressions) {
        if (x.key === metadataField.definition.field_name) {
          thisOperator = x.operator;
          break;
        }
      }
    }

    // Set to operator instead of "=" because it was causing the set operator
    // to be erased when a value was written into the textbox
    if (typeof thisOperator === "undefined") {
      setOperator(operator);
      metadataField.selectedOperator = operator;
    } else {
      setOperator(thisOperator);
      metadataField.selectedOperator = thisOperator;
    }
    setMetadataField(metadataField);
  }, [value]);

  /*
     * !!!!
     *      We can't actually update the criteria here because if we do, the query
     *      gets automatically updated in the useEffect in CriteriaBlockTable
     *
     *      To avoid this, we must keep a separate data structure for the values
     *      and only set the criteria when the OK button is clicked. I think we can
     *      ignore the query object entirely.
     * !!!!
     */
  function handleRemoveExpression() {
    setValue("");
    setOperator("=");
    setSelected([]);
    metadataField.selectedOperator = "=";
    metadataField.values = [];
    metadataField.selected = [];
    setMetadataField(metadataField);
  }

  // when the operator changes
  function handleOnChangeOperator(e) {
    setOperator(e.target.value);
    metadataField.selectedOperator = e.target.value;
    setMetadataField(metadataField);
  }

  function handleOnChangeValue(e) {
    let tempOperator = operator;
    if (isList || operator === "between") {
      metadataField.values = e.target.value.split(',');
      // Required check to ensure accurate "X added" count
    } else if (e.target.value === "") {
      metadataField.values = [];
    } else {
      metadataField.values = [e.target.value];
    }

    metadataField.selectedOperator = tempOperator;
    setOperator(tempOperator);
    setValue(metadataField.values);
    setMetadataField(metadataField);
  }

  // when checkpicker option is selected
  function handleOnSelectValues(selections) {
    setSelected(selections);

    const newSelection = selections.map((selection) => selection.value);
    metadataField.values = newSelection;
    setMetadataField(metadataField);
  }

  return (
    <>
      <tr>
        <td>
          <Form.Label style={{ marginBottom: "0px" }}>
            {
              showDescription ?
                <span onClick={() => setShowDescription(!showDescription)}>
                  {metadataField.definition.field_name}
                  {' '}
                  <FontAwesomeIcon icon={faCircleMinus} fixedWidth />
                </span>
                :
                <span onClick={() => setShowDescription(!showDescription)}>
                  {metadataField.definition.field_name}
                  {' '}
                  <FontAwesomeIcon icon={faCirclePlus} fixedWidth />
                </span>
            }
          </Form.Label>
        </td>
        <td>
          <Form.Control
            as="select"
            value={operator}
            size="sm"
            onChange={handleOnChangeOperator}
          >
            {
              (isList ? ['in', 'not in'] : ['=', '!=', '<', '<=', '>', '>=', 'between'])
                .map(theOperator => (
                  <option key={theOperator} value={theOperator}>{theOperator}</option>
                ))
            }
          </Form.Control>
        </td>
        <td className="metadata-section--multi">
          {!isList
            && (
              <Form.Control
                type="text"
                value={value}
                size="sm"
                onChange={handleOnChangeValue}
              />
            )}
          {isList
            && (
              <MultiSelect
                options={metadataField.definition.allowed_values.map((allowedValue) => ({
                  value: allowedValue,
                  label: allowedValue,
                }))}
                value={selected}
                onChange={handleOnSelectValues}
                searchable={true}
                width="238px"
                sticky={false}
                includeSelectDeselectFooter={true}
              />
            )}
        </td>
        <td>
          <Button variant="light" size="sm" onClick={handleRemoveExpression} title="Clear value">
            <FontAwesomeIcon icon={faUndo} fixedWidth />
          </Button>
        </td>
      </tr>
      <tr hidden={!showDescription}>
        <td colSpan={4}>
          <p style={{ fontSize: "14px" }}>
            {metadataField.definition.description}
          </p>
          <p style={{ fontSize: "14px" }}>
            {metadataField.definition.additional_description &&
              metadataField.definition.additional_description
            }
          </p>
        </td>
      </tr>
    </>
  );
}
