import React, { useState, useEffect } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Checkbox } from "rsuite";
import { Warn } from "../components/Toasts";

import {
  allSame,
  buildMaxDistancesFromValue,
  extractValueFromMaxDistances,
  extractKeyPair,
  fromQueryIntoMaxDistances,
} from "./distanceUtilities";
import { dropdownSearchActions, useDropdownSearchContext, findDefaultMaxDistance } from "./context/stateManager";

export default function Distance() {
  const { state, dispatch } = useDropdownSearchContext();
  const [distance, setDistance] = useState('');
  const [ignoreDistance, setIgnoreDistance] = useState(false);
  const [advancedPlaceholder, setAdvancedPlaceholder] = useState('');
  const [cogBackground, setCogBackground] = useState(allSame(fromQueryIntoMaxDistances(state.queryObject)) ? "" : "cog-advanced-distance");

  function toggleControl(maxDistances) {
    if (allSame(maxDistances)) {
      const distanceValue = extractValueFromMaxDistances(maxDistances);
      if (typeof distanceValue === "undefined") {
        setDistance('');
      } else {
        setDistance(distanceValue);
      }
      setAdvancedPlaceholder('');
      setCogBackground('');
    } else {
      setDistance('');
      setAdvancedPlaceholder("Advanced");
      setCogBackground("cog-advanced-distance");
    }
  }

  useEffect(() => {
    setIgnoreDistance(state.ignoreDistances);
    if (!ignoreDistance) {
      const maxDistances = state.queryObject.max_distances;
      toggleControl(maxDistances);
    }
  }, [state.queryObject, state.ignoreDistances]);

  function handleSetDistance(e) {
    e.stopPropagation();
    const distanceValue = e.target.value;
    setDistance(distanceValue);
  }

  function handleOnBlur(e) {
    const distanceValue = e.target.value;
    const maxDistances = buildMaxDistancesFromValue(distanceValue, state.queryObject);
    setDistance(distanceValue);
    dispatch({
      type: dropdownSearchActions.SET_ADVANCED_DISTANCES,
      payload: { max_distances: maxDistances, ignoreDistances: false },
    });
  }

  function handleToggleDisabled(_, checked) {
    // throw out a warning if there are any adhoc/custom criteria blocks
    if (checked === true && state.queryObject?.adhoc?.length > 0) {
      Warn("Beware! Performing searches with a custom location and distances disabled will return indeterminate results");
    }

    // set ignore distance
    setIgnoreDistance(checked);
    if (checked) {
      dispatch({
        type: dropdownSearchActions.SET_ADVANCED_DISTANCES,
        payload: { max_distances: null, ignoreDistances: true },
      });
    } else {
      const maxDistances = state.queryObject.max_distances;
      dispatch({
        type: dropdownSearchActions.SET_ADVANCED_DISTANCES,
        payload: { max_distances: maxDistances, ignoreDistances: false },
      });
    }
  }

  return (
    <>
      <label htmlFor="distance" className="conjunction-label-text">
        <p>
          <span style={{ textDecoration: "underline", paddingRight: "5px" }}>Distance (km)</span>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                The maximum distance that data sources can be apart from each other. Use the 'disable' checkbox to disregard distances while finding conjunctions.
              </Tooltip>
            }>
            <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
          </OverlayTrigger>
        </p>
      </label>
      <div className="d-flex justify-content-start align-items-middle" style={{ marginTop: "-8px" }}>
        <input
          id="distance"
          type="number"
          min="0"
          max="1500"
          step="25"
          className="form-control form-control-sm"
          aria-label="Kilometers"
          onChange={handleSetDistance}
          onBlur={handleOnBlur}
          value={distance}
          placeholder={advancedPlaceholder}
          disabled={ignoreDistance ? "disabled" : ""}
          key="distance"
        />
        <DistancesModal value={cogBackground} toggleControl={toggleControl} disabled={ignoreDistance} />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Disable Distances</Tooltip>}
          trigger={["hover", "hover"]} // Passed twice to bypass warning system
        >
          <Checkbox
            defaultChecked={false}
            id="disabled"
            name="disabled"
            className="disabled-distances-checkbox noselect"
            checked={ignoreDistance}
            onChange={handleToggleDisabled}
            key="disabled"
            style={{ paddingRight: '10px' }}
          >
            Disable
          </Checkbox>

          {/* <input
            style={{ width: '20px', marginTop: '3px', paddingLeft: '0px', marginRight: '25px', marginLeft: '3px', transform: 'scale(0.75)' }}
            type="checkbox"
            id="disabled"
            name="disabled"
            className=""
            onChange={handleToggleDisabled}
            checked={ignoreDistance}
            key="disabled"
          /> */}
        </OverlayTrigger>
      </div>
    </>
  );
}


function DistancesModal(props) {
  const [show, setShow] = useState(false);
  const { state, dispatch } = useDropdownSearchContext();
  const [maxDistances, setMaxDistances] = useState({});
  const [resetTrigger, triggerReset] = useState(0);

  const cogBackground = props.value;
  const disabled = props.disabled;

  useEffect(() => {
    if (state.exampleLoaded === 'undefined' || Object.keys(maxDistances).length > 0) {
      const newMaxDistances = fromQueryIntoMaxDistances(state.queryObject);
      const orderedMaxDistances = Object.keys(newMaxDistances).sort().reduce(
        (acc, key) => {
          acc[key] = newMaxDistances[key];
          return acc;
        }, {},
      );
      setMaxDistances({ ...orderedMaxDistances });
    } else {
      setMaxDistances({ ...findDefaultMaxDistance(state.exampleLoaded) });
    }
  }, [state.queryObject, resetTrigger]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    document.querySelector("body").classList.remove("modal-open");
  };

  /**
     * Handles updating the value in a specific distance element.
     * The ID contains the information we need to replace the max_distance value,
     * but it's serialized as a
     * string from using the input's id attribute (e.g., "space1-ground1, 500")
     * @param {event} e
     */
  function handleSetDistance(e) {
    const { id } = e.target;
    // Must set to null. Leaving as undefined will cause axios to ignore
    // the distance clause and send an invalid query to the API.
    let val = null;
    if (e.target.value) {
      val = Number(e.target.value);
    }

    const key = id.split(",", 1)[0];
    maxDistances[key] = val;

    setMaxDistances({ ...maxDistances });
  }

  /**
     * Merges the changes recorded in maxDistances with the underlying query object. Merging late
     * allows for the user to cancel the modal window, even if they have changed values.
     */
  function handleOk() {
    if (!_.isEqual(maxDistances, state.queryObject.max_distances)) {
      dispatch({
        type: dropdownSearchActions.SET_ADVANCED_DISTANCES,
        payload: { max_distances: maxDistances, ignoreDistances: false },
      });
    }

    handleClose();
  }

  function handleResetToDefault() {
    setMaxDistances({});
    triggerReset(resetTrigger + 1);
  }


  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Advanced Distances</Tooltip>}
        trigger={["hover", "hover"]} // Passed twice to bypass warning system
      >
        <button type="button" className={`ml-1 p-1 btn btn-sm btn-light ${cogBackground}`} onClick={handleShow} disabled={disabled}>
          <FontAwesomeIcon icon={faCog} fixedWidth size="lg" />
        </button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleOk}
        keyboard={false}
        aria-labelledby="advanced-distances-modal"
        size="lg"
        className="modal--advanced-distances"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="advanced-distances-modal">Advanced Distances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "14px" }}>
            Set specific distances between each criteria block. Setting a value to empty tells
            the search to not consider the distance between those two criteria blocks.
          </p>

          <div className="row">
            <div className="col" key={resetTrigger}>

              {maxDistances
                && Object.entries(maxDistances).map((distance, pairIndex) => {
                  const keypair = extractKeyPair(distance);
                  const distanceKey = distance[0];

                  return (
                    <div className="row d-flex justify-content-start align-items-end" key={distanceKey}>
                      <div className="col-md-3">
                        {
                          keypair.map((key, index) => (
                            <div className="row" key={key + index}>
                              <div className="col">
                                <div className={index === 0 ? "mt-3 border-bottom" : ""}>{key.replaceAll('adhoc', 'custom')}</div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      <div className="col-md-4">
                        {pairIndex === 0
                          && <label htmlFor={distance} className="form-control-label conjunction-label-text">Distance (km)</label>}
                        <input
                          id={distance}
                          type="number"
                          min="0"
                          max="1500"
                          step="25"
                          className="col form-control"
                          title={`${keypair[0]} to ${keypair[1]}`}
                          value={maxDistances[distanceKey]}
                          onChange={handleSetDistance}
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" className="mr-auto" onClick={handleResetToDefault}>Reset</Button>
          <Button variant="light" onClick={handleClose}>Cancel</Button>
          <Button variant="outline-dark" onClick={handleOk}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
