import React from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

export function Error(message, timer = 5000) {
  toast.error(message, {
    position: "top-right",
    autoClose: timer,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Flip,
  });
}

export function Warn(message) {
  toast.warn(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Flip,
  });
}

export function Success(message) {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Flip,
  });
}

export function Info(message) {
  toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Flip,
  });
}

export default function Toasts() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
