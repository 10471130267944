import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { CheckPicker } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dropdownSearchActions, useDropdownSearchContext } from "../context/stateManager";

// Importing styling
import "rsuite/dist/rsuite.min.css";

export default function ConjunctionType() {
  const picker = useRef();
  const { state, dispatch } = useDropdownSearchContext();
  const [value, setValue] = useState(["nbtrace"]);
  const [appliedValue, setAppliedValue] = useState(value);

  const options = [
    { label: "North B-Trace", value: "nbtrace" },
    { label: "South B-Trace", value: "sbtrace" },
    { label: "Geographic", value: "geographic" },
  ];

  useEffect(() => {
    setValue([...state.queryObject.conjunction_types]);
  }, [state.queryObject.conjunction_types]);

  const handleChange = (selected) => {
    setValue(selected);
    setAppliedValue(selected);
  };

  const handleApply = () => {
    setAppliedValue(value);
  };

  useEffect(() => {
    if (!_.isEqual(state.queryObject.conjunction_types, appliedValue)) {
      dispatch({
        type: dropdownSearchActions.SET_CONJUNCTION_TYPES,
        payload: appliedValue,
      });
    }
  }, [appliedValue]);

  return (
    <>
      <label
        className="form-control-label conjunction-label-text d-block mb-1"
      >
        <p>
          <span style={{ textDecoration: "underline", paddingRight: "5px" }}>Conjunction Types</span>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                Use different sets of location data when searching for conjunctions
              </Tooltip>
            }>
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth />
          </OverlayTrigger>
        </p>
      </label>
      <CheckPicker
        id="conjunctionType"
        placeholder="Select values"
        data={options}
        value={value}
        ref={picker}
        onChange={handleChange}
        onClose={handleApply}
        style={{ width: "175px" }}
        searchable={false}
        cleanable={false}
        sticky={false}
      />
    </>
  );
}
