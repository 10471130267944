// Importing Components from node_modules
import React, { useState, useEffect } from 'react';

// Importing Project-defined components
import { useDropdownSearchContext } from './context/stateManager';

const Timer = () => {
  const { state } = useDropdownSearchContext();
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    setIsActive(state.searching);
    if (!state.searching) {
      setSeconds(0);
    }
  }, [state.searching]);

  return (
    <>
      {state.searching &&
        <div className="timer">
          Search has been running for {seconds > 59 && `${Math.floor(seconds / 60)} min`} {seconds % 60} seconds
        </div>
      }
    </>
  );
};

/*
  Following function calculates the time it took to find X number of conjunctions,
  as well as, the total time taken for the complete operation to finish
*/
export default function ResultsTiming() {
  const { state } = useDropdownSearchContext();
  const [totalSearchDuration, setTotalSearchDuration] = useState(null);

  let conjunctionTime;
  let totalTime;

  // Following section just converts the results time to minutes if it exceeds X seconds
  if (state.request.search_result.query_duration !== null && (state.request.search_result.query_duration / 1000) > 60) {
    const min = Math.floor(Number((state.request.search_result.query_duration / 1000)) % 3600 / 60);
    const sec = Math.floor(Number((state.request.search_result.query_duration / 1000)) % 3600 % 60);
    conjunctionTime = `${min}min ${sec}s`;
  } else if (state.request.search_result.query_duration !== null) {
    conjunctionTime = `${state.request.search_result.query_duration / 1000}s`;
  }

  if (isNaN(totalSearchDuration)) {
    totalTime = '';
  } else if (totalSearchDuration > 60) {
    const min = Math.floor(Number(totalSearchDuration) % 3600 / 60);
    const sec = Math.floor(Number(totalSearchDuration) % 3600 % 60);
    totalTime = `(${min}min ${sec}s total)`;
  } else {
    totalTime = `(${totalSearchDuration}s total)`;
  }

  useEffect(() => {
    if (state.request.search_result.completed_timestamp) {
      const start = new Date(state.request.search_result.completed_timestamp);
      const end = new Date(state.request.search_request.requested);
      const duration = (Math.abs(end - start) / 1000);
      setTotalSearchDuration(duration);
    } else if (state.request.search_request.requested) {
      const end = new Date(state.request.search_request.requested);
      const duration = (Math.abs(new Date() - end) / 1000);
      setTotalSearchDuration(duration);
    }
  }, [state.request.search_result.completed_timestamp, state.request.search_request.requested]);

  return (
    <>
      <span id="result-timing">
        <Timer />
        {!state.searching && state.request.search_result.completed_timestamp && (
          <>
            Found
            {' '}
            {state.request.search_result.result_count}
            {' '}
            conjunctions in
            {' '}
            {conjunctionTime}
            {' '}
            {totalTime}
          </>
        )}
      </span>
    </>
  );
}
