import React, { useEffect, useState } from "react";
import { Checkbox } from "rsuite";
import { deployment_type as deploymentType } from "../../../deployment";

import { dropdownSearchActions, useDropdownSearchContext } from "./context/stateManager";

export default function SubMinuteToggle() {
  const [checked, setChecked] = useState(false);
  const { state, dispatch } = useDropdownSearchContext();

  function handleToggle(value, checked) {
    setChecked(checked);
    dispatch({
      type: dropdownSearchActions.TOGGLE_SUBMINUTE_PRECISION,
      payload: checked ? 30 : 60,
    });
  }

  useEffect(() => {
    if (state.queryObject.epoch_search_precision === 60) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [state.queryObject.epoch_search_precision]);

  if (deploymentType === "staging") {
    return (
      <div className="toggle-div">
        <Checkbox
          defaultChecked={true}
          checked={checked}
          onChange={handleToggle}
        >
          Sub-minute<br />precision
        </Checkbox>
      </div>
    );
  } else {
    return null;
  }
}
