// Importing Components from node_modules
import React, { useRef, useState, useEffect } from 'react';
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";
import { useSearchParams } from 'react-router-dom';

// Importing Project-defined components
import AddSpaceGroundEvent from './AddSpaceGroundEvent';
import CriteriaBlocksTable from './CriteriaBlocksTable';
import DateRange from './DateRange';
import DevAlert from '../../../components/alerts/DevAlert';
import Distance from './Distance';
import Examples from './Examples';
import ProgressBar from './ProgressBar';
import QueryEstimate from './QueryEstimate';
import ResultsTable from './ResultsTable';
import ResultsTiming from './ResultsTiming';
import SearchButton from './SearchButton';
import SelectConjunctionType from './selectors/SelectConjunctionType';
import SubMinuteToggle from './SubMinuteToggle';
import SwarmAuroraTools from './SwarmAuroraTools';
import Toasts from '../components/Toasts';
import Tools from './Tools';
import Tutorial from '../components/Tutorial';
import DeepLinks from './DeepLinks';

import { DropdownSeachProvider, useDropdownSearchContext } from './context/stateManager';
import { conjunctionSteps } from './tutorialSteps';

// Import Styling
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'intro.js/introjs.css';
import '../../../styles/conjunction_search/DropDownPage.scss';
import '../../../styles/tutorial/Tutorial.scss';
import '../../../styles/tutorial/introjs-modern.css';

const ErrorMessage = () => {
  return (
    <div className="row justify-content-center my-4 mx-1">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6">
        <Alert variant="danger">
          <Alert.Heading>Error connecting to AuroraX API</Alert.Heading>
          <hr />
          Sorry, an error occurred while trying to connect to the
          AuroraX API. Please try refreshing the page, and if the
          problem persists then contact support at support@aurorax.space.
        </Alert>
      </div>
    </div>
  );
};

const LoadingMessage = () => {
  return (
    <div className="w-100 py-5 text-center">
      Fetching data sources from AuroraX
      <FontAwesomeIcon icon={faLoader} fixedWidth spin style={{ marginLeft: "5px" }} />
    </div>
  );
};

const DropDownPageContainer = () => {
  const [params] = useSearchParams();

  return (
    <DropdownSeachProvider requestID={params.get("requestID")}>
      <DropDownPage disableTutorial={!!params.get("requestID")} />
    </DropdownSeachProvider>
  );
};


const DropDownPage = ({ disableTutorial }) => {
  const { state } = useDropdownSearchContext();
  const searchBtnRef = useRef(null);
  // Defining state to determine window size and determine if resize pop-up is visible
  const [width, setWidth] = useState(window.innerWidth);

  // Retrieving save setting & extra setup to be able to decide if tutorial is shown
  let storage = !!(localStorage.getItem('hide_tutorial_dropdown'));
  const [stepsEnabled, setStepsEnabled] = useState(!storage && !disableTutorial);

  const [deepLinksActive, setDeepLinksActive] = useState(null);

  /*
    The following is used to ensure that the resize pop-up is only visible within a certain range
    of window width (i.e. avoids it remaining it open, when window fits desktop)
  */
  const handleResize = () => { setWidth(window.innerWidth); };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, [width]);

  return (
    <>
      {/*
        Following expression only renders the resize pop-up if the window's inner width is
        within the following range
      */}
      {(width < 1443)
        && (
          <DevAlert
            alertType="Note"
            alertColor="alert--lightgreen"
          >
            You are currently viewing this page using a screen smaller than the optimized size.
            Some things might not look quite right, so we recommend using a larger screen or zooming out.
          </DevAlert>
        )}

      <Tutorial
        steps={conjunctionSteps}
        sessionName='hide_tutorial_dropdown'
        stepsEnabled={stepsEnabled && state.allDataSources.length > 0}
        setStepsEnabled={setStepsEnabled}
      />

      {
        state.allDataSources.length == 0 ?
          state.error === true ?
            <ErrorMessage />
            : <LoadingMessage />
          :
          <div className="dropdown-container">

            { /* Display any error, warn, info, and success messages */}
            <Toasts />

            { /* Input Panel */}
            <div id="input-panel" className="row">

              { /* Input fields */}
              <div className="input-panel--left">

                { /* Date-range, distance, conjunction-type, add space & ground */}
                <div className="row input-fields--top">

                  { /* Date-range, distnace, conjunction-type */}
                  <div id="input-fields--inputs">
                    <div className="row d-flex justify-content-start">
                      <div id="date-range-input">
                        <DateRange />
                      </div>
                      <div id="distance-input">
                        <Distance />
                      </div>
                      <div className="conjunction-type-input">
                        <SelectConjunctionType />
                      </div>
                      <div>
                        <SubMinuteToggle />
                      </div>
                    </div>
                  </div>

                  { /* add space & ground */}
                  <div id="input-fields--buttons">
                    <AddSpaceGroundEvent />
                  </div>
                </div>

                { /* Criteria-block-table */}
                <div id="input-fields--bottom" className="row">
                  <CriteriaBlocksTable />
                </div>
              </div>

              { /* Examples, Tools, Deep-Links, and Big Search Button */}
              <div id="conjunction-right-sidebar">

                { /* Examples */}
                <div className="row mb-1 examples-row">
                  <div id="load-example-label" style={{ marginTop: "2px" }}>
                    Load Examples:
                  </div>
                  <div className="d-flex sidebar-buttons">
                    <Examples
                      setStepsEnabled={setStepsEnabled}
                    />
                  </div>
                </div>

                { /* Tools */}
                <div id="conjunction-tool-row" className="row mb-1">
                  <div id="tool-label" style={{ marginTop: "5px" }}>
                    Tools:
                  </div>
                  <div className="d-flex sidebar-buttons">
                    <Tools searchRef={searchBtnRef} />
                  </div>
                </div>

                <div id="swarm-tool-row" className="row mb-1">
                  <div id="swarm-tool-label" style={{ marginTop: "5px" }}>
                    Swarm-Aurora:
                  </div>
                  <div className="d-flex sidebar-buttons">
                    <SwarmAuroraTools />
                  </div>
                </div>

                <div id="deep-links-row" className="row">
                  <div id="deep-links-label" style={{ marginTop: "5px" }}>
                    Deep Links:
                  </div>
                  <div className="d-flex sidebar-buttons">
                    <DeepLinks deepLinksActive={deepLinksActive} setDeepLinksActive={setDeepLinksActive} />
                  </div>
                </div>

                { /* Big Search Button */}
                <div className="row">
                  <div style={{ padding: 0 }}>
                    <SearchButton searchRef={searchBtnRef} />
                  </div>

                  <div className="progress-query-container">
                    <QueryEstimate />
                    <ProgressBar />
                  </div>
                </div>

                <div className="row" style={{ minHeight: "35px" }}>
                  <ResultsTiming />
                </div>

              </div>
            </div>

            { /* Results Table */}
            <div className="row">
              <div className="col results-container">
                <ResultsTable deepLinksActive={deepLinksActive} />
              </div>
            </div>
          </div>
      }
    </>
  );
};

export default DropDownPageContainer;