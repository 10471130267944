// Importing Components from node_modules
import React, { useState, useEffect } from "react";
import axios from "axios";
import { JsonViewer } from '@textea/json-viewer';
import { v4 as uuidv4 } from "uuid";
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faClipboard, faDatabase, faExternalLink, faList, faShareNodes, faUndo, faUpload } from "@fortawesome/pro-regular-svg-icons";

// Importing project-defined components
import { Error } from "../components/Toasts";
import { describeQueryUrl, conjunctionSearchResponse } from "../../../utils/auroraXapi";

import LoadConjunctionModal from "./LoadConjunctionModal";
import { dropdownSearchActions, useDropdownSearchContext } from "./context/stateManager";

export default function Tools({ searchRef }) {
  const { dispatch } = useDropdownSearchContext();

  function resetAll() {
    dispatch({
      type: dropdownSearchActions.RESET_ALL,
    });
  }

  return (
    <>
      <div className="mr-1">
        <Button variant="outline-dark" size="sm" onClick={resetAll}>
          Reset All
          <FontAwesomeIcon icon={faUndo} fixedWidth className="ml-1" />
        </Button>
      </div>
      <div className="mr-1">
        <ShareConjunctionModal />
      </div>
      <div className="mr-1">
        <MoreToolsDropdown searchRef={searchRef} />
      </div>
    </>
  );
}

function describeQuery(query) {
  const dataPromise = axios
    .post(describeQueryUrl, query)
    .then((resp) => resp.data)
    .catch((err) => Error(err.errorMessage));

  return dataPromise;
}

function AboutQueryModal({ show, setShow }) {
  const [copyMsg, setCopyMsg] = useState("Copy to Clipboard");
  const { state } = useDropdownSearchContext();
  const [description, setDescription] = useState("");

  const handleClose = () => {
    setShow(false);
    document.querySelector("body").classList.remove("modal-open");
  };

  useEffect(() => {
    if (show) {
      describeQuery(state.queryObject)
        .then(data => setDescription(data))
        .catch(err => Error(err.errorMessage));
    }
  }, [show]);

  function copyToClipboard() {
    navigator.clipboard.writeText(JSON.stringify(state.queryObject, null, 2));
    setCopyMsg("Copied!");

    setTimeout(() => {
      setCopyMsg("Copy to Clipboard");
    }, 1500);
  }

  return (

    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      aria-labelledby="debug-modal"
      size="lg"
      scrollable={true}
      className="modal--about-query"
    >
      <Modal.Header closeButton>
        <Modal.Title id="about-query-modal">About Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <h4>Descriptive Text</h4>
        <pre style={{ whiteSpace: "pre-wrap", fontSize: "13px" }}>{description}</pre>

        <hr />

        <h4>
          JSON Format
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {copyMsg}
              </Tooltip>
            }>
            <FontAwesomeIcon icon={faClipboard} fixedWidth
              data-tip
              data-for="copy"
              data-place="right"
              onClick={copyToClipboard}
            />
          </OverlayTrigger>
        </h4>
        <JsonViewer
          value={state.queryObject}
          style={{ fontSize: "12px" }}
          enableClipboard={false}
          quotesOnKeys={false}
          displayDataTypes={false}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ShareConjunctionModal = () => {
  const { state } = useDropdownSearchContext();
  const [copyMsg, setCopyMsg] = useState("Click to copy");
  const [show, setShow] = useState(false);
  const shareUrl = `${window.location.protocol}//${window.location.host}/conjunctionSearch/standard?requestID=${state.queryID}`;

  const handleClose = () => {
    setShow(false);
    document.querySelector("body").classList.remove("modal-open");
  };

  const handleShow = () => {
    setShow(true);
  };

  function copyToClipboard(stringToCopy) {
    navigator.clipboard.writeText(stringToCopy);
    setCopyMsg("Copied!");

    setTimeout(() => {
      setCopyMsg("Click to copy");
    }, 1500);
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-outline-dark"
        onClick={handleShow}
        disabled={state.queryID === null || state.searching}
      >
        Share
        <FontAwesomeIcon icon={faShareNodes} fixedWidth className="ml-1" />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        aria-labelledby="debug-modal"
        size="md"
        scrollable={true}
        className="modal--about-query"
      >
        <Modal.Header closeButton>
          <Modal.Title id="share-conjunction-modal">Share this conjunction search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "14px" }}>
            Share this unique conjunction search with others to let them see its
            parameters and results.
          </p>
          <p style={{ fontSize: "14px" }}>You can share it two ways: 1) using the URL,
            or 2) using the request ID in combination with
            the <strong> More</strong> -&gt; <strong>Load search using request ID</strong> buttons.
          </p>

          <div style={{ textDecoration: "underline", fontWeight: "bold", marginBottom: "3px", fontSize: "14px" }}>URL</div>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {copyMsg}
              </Tooltip>
            )}>
            <div className="w-100 text-center py-2 alert-info rounded d-flex justify-content-between"
              style={{ cursor: "pointer", fontSize: "14px" }}
              onClick={() => copyToClipboard(shareUrl)}
            >
              <div className="ml-3" style={{ width: "90%" }}>
                <b>{shareUrl}</b>
              </div>
              <div style={{ width: "10%" }}>
                <FontAwesomeIcon icon={faClipboard} fixedWidth className="mr-3 d-block m-auto h-100" />
              </div>
            </div>
          </OverlayTrigger>

          <div style={{ textDecoration: "underline", fontWeight: "bold", marginBottom: "3px", fontSize: "14px", marginTop: "15px" }}>Request ID</div>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {copyMsg}
              </Tooltip>
            )}>
            <div className="w-100 text-center py-2 alert-info rounded d-flex justify-content-between" style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => copyToClipboard(state.queryID)} >
              <div className="ml-3" style={{ width: "90%" }}>
                <b>{state.queryID}</b>
              </div>
              <div style={{ width: "10%" }}>
                <FontAwesomeIcon icon={faClipboard} fixedWidth className="mr-3 d-block m-auto h-100" />
              </div>
            </div>
          </OverlayTrigger>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>);
};

function MoreToolsDropdown({ searchRef }) {
  const { state } = useDropdownSearchContext();

  const [showLoadModal, setShowLoadModal] = useState(false);
  const [showRawResultsModal, setShowRawResultsModal] = useState(false);
  const [showAboutQueryModal, setShowAboutQueryModal] = useState(false);

  return (
    <>
      <Dropdown alignRight>
        <Dropdown.Toggle variant="outline-dark" size="sm" id="tools-more-dropdown">
          More
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ fontSize: "0.875rem" }}>
          <Dropdown.Item onClick={() => setShowAboutQueryModal(true)} className="tools-dropdown px-3">
            <span className="tools-dropdown-item">
              <FontAwesomeIcon icon={faCircleInfo} className="mr-2" />About query
            </span>
          </Dropdown.Item>
          <Dropdown.Item disabled={state.queryID === null || state.searching} onClick={() => setShowRawResultsModal(true)} className="tools-dropdown px-3">
            <span className="tools-dropdown-item">
              <FontAwesomeIcon icon={faList} className="mr-2" />View raw results
            </span>
          </Dropdown.Item>
          <Dropdown.Item disabled={state.searching} onClick={() => setShowLoadModal(true)} className="tools-dropdown px-3">
            <span className="tools-dropdown-item">
              <FontAwesomeIcon icon={faUpload} className="mr-2" />Load search using request ID
            </span>
          </Dropdown.Item>
          <Dropdown.Item className="tools-dropdown px-3" href="/data/availability" target="_blank">
            <span className="tools-dropdown-item">
              <FontAwesomeIcon icon={faDatabase} className="mr-2" />View Data Availability
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <AboutQueryModal show={showAboutQueryModal} setShow={setShowAboutQueryModal} />
      <LoadConjunctionModal show={showLoadModal} setShow={setShowLoadModal} searchRef={searchRef} />
      <RawResults show={showRawResultsModal} setShow={setShowRawResultsModal} />
    </>
  );
}

function RawResults({ show, setShow }) {
  const [copyMsg, setCopyMsg] = useState("Copy to Clipboard");
  const { state } = useDropdownSearchContext();

  const handleClose = () => {
    setShow(false);
    document.querySelector("body").classList.remove("modal-open");
  };

  function copyToClipboard(content) {
    if (typeof content === "string") {
      navigator.clipboard.writeText(content);
    } else {
      navigator.clipboard.writeText(JSON.stringify(content, null, 2));
    }
    setCopyMsg("Copied!");

    setTimeout(() => {
      setCopyMsg("Copy to Clipboard");
    }, 1500);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      aria-labelledby="raw-results-modal"
      size="lg"
      scrollable={true}
      className="modal--raw-results"
    >
      <Modal.Header closeButton>
        <Modal.Title id="raw-results-modal">Raw Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <h4>
          Search Results Summary
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {copyMsg}
              </Tooltip>
            }>
            <FontAwesomeIcon icon={faClipboard} fixedWidth
              data-tip
              data-for="copy"
              data-place="right"
              onClick={() => copyToClipboard(state.request.search_result)}
            />
          </OverlayTrigger>

          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                View raw search results summary
              </Tooltip>
            }>
            {
              state.request.search_request.request_id !== null &&
              <FontAwesomeIcon icon={faExternalLink} fixedWidth
                data-tip
                data-for="summary-external-link"
                data-place="right"
                onClick={() => window.open(`${conjunctionSearchResponse}/${state.request.search_request.request_id}`, "_blank")}
              />
            }
          </OverlayTrigger>
        </h4>

        <JsonViewer
          value={state.request.search_result}
          style={{ fontSize: "12px" }}
          enableClipboard={false}
          quotesOnKeys={false}
          displayDataTypes={false}
        />

        <hr />

        <h4>
          Search Logs
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {copyMsg}
              </Tooltip>
            }>
            <FontAwesomeIcon icon={faClipboard} fixedWidth
              data-tip
              data-for="copy"
              data-place="right"
              onClick={() => copyToClipboard(
                state.request.logs.map(x => (
                  `[${x.level.toUpperCase().padEnd(5, " ")}] ${x.timestamp}: ${x.summary}`
                )).join("\n"))}
            />
          </OverlayTrigger>

          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                View raw search logs
              </Tooltip>
            }>
            {
              state.request.search_request.request_id !== null &&
              <FontAwesomeIcon icon={faExternalLink} fixedWidth
                data-tip
                data-for="logs-external-link"
                data-place="right"
                onClick={() => window.open(`${conjunctionSearchResponse}/${state.request.search_request.request_id}`, "_blank")}
              />
            }
          </OverlayTrigger>
        </h4>

        <table>
          <tbody>
            {state.request.search_result.completed_timestamp !== null && (
              state.request.logs
                .map(function (x) {
                  // shorten summary messages
                  if (typeof (x) !== "undefined") {
                    let short_summary = x["summary"];
                    if (short_summary.length > 1000) {
                      short_summary = short_summary.substr(0, 1000) + "...";
                    }
                    x["short_summary"] = short_summary;
                  }

                  // return
                  return (
                    <tr key={uuidv4()} style={{ verticalAlign: "top" }}>
                      <td>
                        <code><p style={{ fontSize: "12px", width: "55px", marginBottom: "3px" }}>
                          [{x.level.toUpperCase()}]
                        </p></code>
                      </td>
                      <td>
                        <code><p style={{ fontSize: "12px", width: "205px", marginBottom: "3px" }}>
                          {x.timestamp}:
                        </p></code>
                      </td>
                      <td>
                        <code><p style={{ fontSize: "12px", marginBottom: "3px", textAlign: "left" }}>
                          {x.short_summary}
                        </p></code>
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>

        <hr />

        <h4>
          Results Data
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {copyMsg}
              </Tooltip>
            }>
            <FontAwesomeIcon icon={faClipboard} fixedWidth
              data-tip
              data-for="copy"
              data-place="right"
              onClick={() => copyToClipboard(state.request.data)}
            />
          </OverlayTrigger>

          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {copyMsg}
              </Tooltip>
            }>
            {
              state.request.search_request.request_id !== null &&
              <FontAwesomeIcon icon={faExternalLink} fixedWidth
                data-tip
                data-for="raw-data-external-link"
                data-place="right"
                onClick={() => window.open(`${conjunctionSearchResponse}/${state.request.search_request.request_id}/data`, "_blank")}
              />
            }
          </OverlayTrigger>
        </h4>

        <JsonViewer
          value={state.request.data}
          style={{ fontSize: "12px" }}
          enableClipboard={false}
          quotesOnKeys={false}
          displayDataTypes={false}
          defaultInspectDepth={2}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal >
  );
}
