import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faLocationCrosshairs, faLocationDot } from "@fortawesome/pro-regular-svg-icons";

import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';
import { Warn } from "../components/Toasts";
import { SelectPicker } from "rsuite";

const commonLocations = {
  calgary: { name: 'Calgary, CA', lat: 51.0447, lon: -114.0719 },
  edmonton: { name: 'Edmonton, CA', lat: 53.5461, lon: -113.4937 },
  lake_minnewanka: { name: 'Lake Minnewanka, CA', lat: 51.247789, lon: -115.500443 },
  forgetmenot_pond: { name: 'Forget-Me-Not Pond, CA', lat: 50.797869, lon: -114.84728 },
  poker_flat: { name: 'Poker Flat Research Range, US', lat: 65.125687, lon: -147.491943 },
};

export default function Coordinate({ type, index }) {
  const { state, dispatch } = useDropdownSearchContext();
  const commonLocationValue = null;

  const handleLatOnChange = (e) => {
    const newLat = e.target.value;
    if (newLat < -90 || newLat > 90) {
      Warn("Latitude bounds is -90 to 90. Please correct and retry.");
    }
    dispatch({
      type: dropdownSearchActions.SET_CRITERIA_ADHOC,
      payload: {
        type: type,
        index: index,
        value: [newLat, state.queryObject[type][index].locations[0].lon],
      },
    });
  };

  const handleLonOnChange = (e) => {
    const newLon = e.target.value;
    if (newLon < -180 || newLon > 180) {
      Warn("Latitude bounds is -180 to 180. Please correct and retry.");
    }
    dispatch({
      type: dropdownSearchActions.SET_CRITERIA_ADHOC,
      payload: {
        type: type,
        index: index,
        value: [state.queryObject[type][index].locations[0].lat, newLon],
      },
    });
  };

  const handleExternalMapLoad = () => {
    const lat = document.getElementById(index + "lat").value;
    const lon = document.getElementById(index + "lon").value;
    const url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lon;
    window.open(url, '_blank').focus();
  };

  const handleLoadCurrentLocation = () => {

    function success(data) {
      dispatch({
        type: dropdownSearchActions.SET_CRITERIA_ADHOC,
        payload: {
          type: type,
          index: index,
          value: [data.coords.latitude, data.coords.longitude],
        },
      });
    }

    function failure(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          Warn("User denied the request for retrieving location");
          break;
        case error.POSITION_UNAVAILABLE:
          Warn("Location information is unavailable");
          break;
        case error.TIMEOUT:
          Warn("The request to get user location timed out");
          break;
        case error.UNKNOWN_ERROR:
          Warn("An unknown error occurred while retrieving your location");
          break;
      }
    }

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const userLocation = navigator.geolocation;

    if (userLocation) {
      userLocation.getCurrentPosition(success, failure, options);
    } else {
      Warn("Location retrieval is not supported by this browser");
    }
  };

  const handleCommonLocationChange = (value, e) => {
    e.target.blur();
    const latElement = document.getElementById(index + "lat");
    const lonElement = document.getElementById(index + "lon");
    latElement.value = commonLocations[value].lat;
    lonElement.value = commonLocations[value].lon;
  };

  return (
    <>
      <div className="pt-2 d-flex justify-content-start align-items-middle" style={{ marginTop: "-8px" }}>
        <label htmlFor={index + "lat"} className="form-control-label pt-2 pr-2">Latitude:</label>
        <input
          id={index + "lat"}
          type="number"
          min="-90"
          max="90"
          step="0.1"
          placeholder="0.0"
          className="form-control form-control-sm col-2"
          aria-label="Geographic Location"
          onChange={handleLatOnChange}
          value={state.queryObject[type][index].locations[0].lat}
          key={index + "lat"}
          style={{ maxWidth: '115px', height: '35px' }}
        />
        <span className="pt-2 px-2">Longitude:</span>
        <input
          id={index + "lon"}
          type="number"
          min="-180"
          max="180"
          step="0.1"
          placeholder="0.0"
          className="form-control form-control-sm col-2"
          aria-label="Geographic Location"
          onChange={handleLonOnChange}
          value={state.queryObject[type][index].locations[0].lon}
          key={index + "lon"}
          style={{ maxWidth: '125px', height: '35px' }}
        />

        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip>
              Bounds for these values are:<br />
              latitude [-90, 90]<br />
              longitude [-180, 180]
            </Tooltip>
          }>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ fontSize: '14px', marginLeft: '3px', marginTop: '11px' }}
            fixedWidth
          />
        </OverlayTrigger>

        <Button
          className="custom-latlon-btn"
          variant="outline-dark"
          onClick={handleExternalMapLoad}
          style={{ height: '35px' }}
        >
          See map{' '}
          <FontAwesomeIcon
            icon={faLocationDot}
            fixedWidth
          />
        </Button>

        <Button
          className="custom-latlon-btn"
          variant="outline-dark"
          onClick={handleLoadCurrentLocation}
          style={{ height: '35px' }}>
          Use my location{' '}
          <FontAwesomeIcon
            icon={faLocationCrosshairs}
            fixedWidth
          />
        </Button>

        <SelectPicker
          id={'custom' + index + '-common-locations'}
          placeholder={"Common locations"}
          data={Object.keys(commonLocations).sort().map((key) => { return { label: commonLocations[key].name, value: key }; })}
          style={{ fontSize: '14px', width: '165px', marginLeft: '5px' }}
          onChange={handleCommonLocationChange}
          value={commonLocationValue}
        />
      </div>
    </>
  );
}