import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from "@fortawesome/pro-regular-svg-icons";

import { Warn } from '../components/Toasts';
import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';
import { sumTotalNumberOfCriterialBlocks } from "./CriteriaBlocksTable";

export default function RemoveCriteria({ type, index }) {
  const { state, dispatch } = useDropdownSearchContext();

  /*
    Does a check to ensure that there are at least 1 of criteria block type (ground, event, and space)
    available before removing
  */
  function removeCriteriaBlock() {
    if (sumTotalNumberOfCriterialBlocks(state) > 2) {
      dispatch({
        type: dropdownSearchActions.REMOVE_CRITERIA_BLOCK,
        payload: {
          type: type,
          index: index,
        },
      });
    } else {
      Warn("At least 2 criteria blocks are required for a conjunction search");
    }
  }

  return (
    <Button
      className="remove-critieria-row"
      variant="light"
      size="sm"
      onClick={removeCriteriaBlock}
      title="Remove criteria block"
    >
      <FontAwesomeIcon icon={faMinus} fixedWidth />
    </Button>
  );
}
