import React from 'react';

export const conjunctionSteps = [
  {
    intro: <TutorialStart />,
  },
  {
    element: "#date-range-input",
    intro: "<p>Use this widget to set the timeframe that you want to search for conjunctions " +
      "in. The start and end minutes are inclusive.</p>",
  },
  {
    element: "#distance-input",
    intro: "<p>Using this input box, you can set the maximum distance that a conjunction will " +
      "be found for between instruments.</p><p>For more complicated searches, you can use the " +
      "<span className='fa fa-cog fa-fw'></span> icon to set advanced distances. For example, " +
      "say you wanted search for triple conjunctions between any THEMIS ASI, any THEMIS " +
      "spacecraft, and any Swarm spacecraft. You want the distance between the ASIs and the " +
      "spacecrafts to be 500km, but you don't care about the distance between the THEMIS and " +
      "Swarm spacecrafts (space1-space2 distance). Using the advanced distances functionality, " +
      "you can do this query by setting the distance of \"ground1-space1\" and \"ground1-space2\" " +
      "to 500km, and setting the \"space1-space2\" distance to an empty input box (which " +
      "signifies that that distance can be anything).</p><p>Lastly, the distance value can also be " +
      "completely ignored, resulting in conjunctions 'that exist' instead of 'that exist within a " +
      "distance'. This can be particularly helpful for searching times where say PFISR and any THEMIS " +
      "ASI were operating.</p>",
  },
  {
    element: ".conjunction-type-input",
    intro: "<p>Using this dropdown, you can select what kind of location information the " +
      "search algorithm should use to determine conjunctions. Note that no values selected is " +
      "the same as all values selected.</p><p>North and South B-Trace values are magnetic " +
      "footprints derived by SSCWeb, for spacecrafts. For ground-based instruments, these " +
      "magnetic footprints are calculated using the geographic location of the site " +
      "translated to magnetic coordinates with the help of AACGM.",
  },
  {
    element: "#input-fields--bottom",
    intro: "<p>These rows are called \"criteria blocks\", and are the selectors used to tell the " +
      "search engine what to find conjunctions between. The default is to search for \"double " +
      "conjunctions\", where the algorithm is finding times between two sets of data sources " +
      "that are within a certain distance apart.</p><p>Note that no values selected in a dropdown " +
      "means that same as all values selected. You must select at least one value in each criteria " +
      "block.</p>",
  },
  {
    element: "#input-fields--bottom",
    intro: "<p>Each criteria block (row) is evaluated as a logical \"AND\" statement with other " +
      "criteria blocks. Each selection within a criteria block is evaluated as a logical \"OR\".</p>" +
      "<p>For example, with \"themis-asi\" set as the program for Ground 1, and \"swarm\" set as the " +
      "program for Space 1, this will search for conjunctions between any one THEMIS ASI platform " +
      "AND any one Swarm spacecraft. One conjunction result could be between THEMIS ASI Gillam AND " +
      "Swarm A, and another could between THEMIS ASI Fort Smith AND Swarm C.</p>",
  },
  {
    element: "#input-fields--bottom",
    intro: "<p>Searches can be further enhanced by adding more ground and space criteria blocks, " +
      "effectively searching for triple or quadruple conjunctions, or more.</p>",
  },
  {
    element: "#input-fields--buttons",
    intro: "<p>Add additional ground, space, events, or custom location criteria blocks using these buttons.</p><p>There must " +
      "be at least 2 criteria blocks to perform a search, with a maximum of 10 allowed.</p>",
  },
  {
    element: ".metadata-col",
    intro: "<p>Conjunction searches can be further enriched by filtering on any number of metadata " +
      "fields.</p><p>For spacecraft, examples of metadata fields are the spacecraft's region (as " +
      "defined by SSCWeb), or if an onboard instrument is operating or not (ie. ePOP FAI collected " +
      "data).</p><p>For ground-based instruments, examples are machine learning image content " +
      "predictions, or operating mode (ie. nominal cadence, burst mode, etc.). These metadata " +
      "can be any amount different fields, with values being numbers or strings.</p>",
  },
  {
    element: ".search-button-not-searching",
    intro: "<p>Use the \"Search\" button to initiate a conjunction search.</p><p>Depending on the " +
      "complexity of the search, it can take from seconds to many minutes to return results. The search " +
      "engine operates asynchronously, so you can do a search across years of data and come back in " +
      "15 minutes and the results will be loaded.</p><p>Complexity increases when adding more " +
      "programs/platforms within a criteria block, adding more criteria blocks, increasing the " +
      "timeframe, or adding metadata filters.</p>",
    position: "left",
  },
  {
    element: ".examples-row",
    intro: "<p>We have a series of examples available for quick use to help get the hang of what's " +
      "possible using the search engine.</p><p>Examples 1-4 are different double conjunction searches, " +
      "example 5 is a triple conjunction search, and example 6 is a quadruple conjunction search.</p>" +
      "<p>Example 7 is an demonstration of using machine learning metadata fields to only find conjunctions " +
      "when a trained model thinks Amorphous Pulsating Aurora (APA) is visible in the THEMIS ASI data.</p>",
    position: "left",
  },
  {
    element: "#conjunction-tool-row",
    intro: "<p>We have various tools to help use the conjunction search, such as resetting the interface " +
      "back to default, or viewing additional details about the query.</p><p>The \"About Query\" item " +
      "includes a text-based interpretation of the logic to help understand what the search algorithm is " +
      "exactly looking for.</p>",
    position: "left",
  },
  {
    element: "#swarm-tool-row",
    intro: "<p>Integrations with Swarm-Aurora are available in this row of buttons.</p><p>You can do a " +
      "conjunction search and then open all results in Swarm-Aurora to quickly skip through results. " +
      "Further, you can download a \"custom import file\" and load it into Swarm-Aurora manually (ie. " +
      "save it for later or share it with others).</p>",
    position: "left",
  },
  {
    intro: <TutorialEnd />,
  },
];

function TutorialStart() {
  return (
    <>
      <h3>Welcome to the <br />AuroraX Conjunction Search</h3>
      <hr />
      <p>
        The AuroraX Conjunction Search engine is a tool for finding conjunctions
        between ground-based ASIs and space-based instruments. It helps researchers
        streamline the process of "event discovery", quickly narrowing down
        the possible times that may contain interesting data.
      </p>
      <p>
        You can learn more about the conjunction search on our documentation
        website{' '}
        <a
          href="https://docs.aurorax.space/web/conjunction_search"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>.
      </p>

      <p>Let us show you around.</p>

      <div className="skip-tutorial">
        <input
          id="tutorial-checkbox"
          type="checkbox"
          checked={!!localStorage.getItem('hide_tutorial_dropdown')}
          onChange={() => { }} // used to remove warnings
        />
        <label htmlFor="tutorial-checkbox" style={{ fontSize: "15px" }}>
          {' '}Don't show this again
        </label>
      </div>
    </>
  );
}

function TutorialEnd() {
  return (
    <>
      <p>That's about it!</p>
      <p>
        For more information about the Conjunction Search you can view our
        documentation{' '}
        <a
          href="https://docs.aurorax.space/web/conjunction_search"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>.
      </p>
      <p> Have fun and we hope you find this tool useful!</p>

      <div className="skip-tutorial">
        <input
          id="tutorial-checkbox"
          type="checkbox"
          checked={!!localStorage.getItem('hide_tutorial_dropdown')}
          onChange={() => { }} // used to remove warnings
        />
        <label htmlFor="tutorial-checkbox" style={{ fontSize: "15px" }}>
          {' '}Don't show this again
        </label>
      </div>
    </>
  );
}

