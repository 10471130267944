import React from 'react';
import { ProgressBar as ReactBar } from 'react-bootstrap';

import { useDropdownSearchContext } from './context/stateManager';


function ProgressBar() {
  const { state } = useDropdownSearchContext();

  return (
    <>
      {state.progress !== null && (
        <ReactBar
          now={state.progress}
          label={`${Math.round(state.progress)}%`}
          variant="info"
          style={{ height: "20px" }}
        />
      )}
    </>
  );

}

export default ProgressBar;