import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import { deployment_type } from '../../../deployment';
import { initialQuery, useDropdownSearchContext } from './context/stateManager';

function QueryEstimate() {
  const { state } = useDropdownSearchContext();
  const [showModal, setShowModal] = useState(false); // used by modal
  const [estimate, setEstimate] = useState(null);

  const [updateEstimateTimer, setUpdateEstimateTimer] = useState(null);

  // Show/close of modal
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    document.querySelector('body').classList.remove('modal-open');
  };

  useEffect(() => {
    if (deployment_type === "production") {
      setEstimate("");
    } else if (JSON.stringify(state.queryObject) !== JSON.stringify(initialQuery)) {
      // because the query state change gets triggered multiple times from different
      // components after the user makes a change, I've introduced a timeout function
      // that will only run after the last state change + 2 seconds
      if (updateEstimateTimer !== null) {
        // clear the previous timeout
        clearTimeout(updateEstimateTimer);
      }

      // set a new timeout
      setUpdateEstimateTimer(setTimeout(() => {
        // assuming there was an error if the Data API doesn't respond within 5 seconds
        setTimeout(() => {
          setEstimate("0.1");
          setUpdateEstimateTimer(null);
        }, 100);
      }, 1000));
    }
  }, [state.queryObject]);

  if (deployment_type == "production") {
    // don't show query estimate, instead show some text and
    // a modal to learn about query times
    return (
      <>
        <p
          onClick={handleShow}
          style={{ cursor: "pointer" }}
        >
          Search taking long? Learn more:{" "}
          <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
        </p>

        <Modal
          show={showModal}
          className="about-query-time--modal"
          onHide={handleClose}
          keyboard={false}
          size="lg"
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="advaned-distances-modal">About Conjunction Search Times</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>How long should seaches take?</h4>
            <p>
              Depending on the complexity of the search outlined below, it can
              take from seconds to many minutes to return results. The search
              engine operates asynchronously, so you can do a search across years
              of data and come back in 15 minutes and the results will be loaded.
            </p>
            <p>
              If you want some more control over the conjunction search results, you
              can export them using the buttons below the results table (ie. ASCII, CSV,
              other formats). You can also do conjunction searches using PyAuroraX,
              IDL-AuroraX or the API directly. You can learn more{' '}
              <a href="https://docs.aurorax.space" target="_blank" rel="noreferrer">here</a>.
            </p>
            <h4 className="mt-4">What impacts the search time?</h4>
            <p>
              The amount of time a search takes depends on the complexity of your
              query. Caching can also play a significant factor in reducing search
              time.
            </p>
            <p style={{ marginBottom: "5px" }}>
              The key aspects of a conjunction search that affect the search time are:
            </p>
            <ul>
              <li>Number of criteria blocks (ie. ground1, space1, etc.)
                &#8592; <strong>exponential</strong></li>
              <li>Number of days (date range) &#8592; <strong>~linear</strong></li>
              <li>Number of programs, platforms, and/or instrument_types selected
                in a given criteria block &#8592; <strong>~linear</strong></li>
              <li>Number of conjunction types (ie. north B-trace, south B-trace,
                etc.) &#8592; <strong>linear</strong></li>
              <li>If metadata filters set &#8592; <strong>faster search</strong></li>
            </ul>
            <p>
              As more criteria blocks are included, the search time increases
              exponentially. This is because of the introduction of additional
              data source combinations for the search algorithm to evaluate.
            </p>
            <p>
              As the date range is extended, the search time increases somewhat
              linearly. For a year-long - or multi year - search, it is not
              uncommon to have a search time of tens of minutes, depending on
              other complexity factors.
            </p>
            <p>
              Similarly, as more programs, platforms, and/or instrument types
              in each criteria block are set, the search time increases somewhat
              linearly. Remember that if these fields are left empty then this
              means all options are selected.
            </p>
            <p>
              The number of conjunction types (north B-trace, south B-trace,
              geographic) causes the search time to increase linearly. This is
              because for each conjunction type, the set of database queries
              and under-the-hood tasks are repeated. So, if a search with just
              one conjunction type takes 10 seconds, then that same search with
              two conjunction types would normally take 20 seconds (not
              accounting for any caching).
            </p>
            <p>
              An effect of using the metadata filters is that it reduce the search
              time. The more that are set, the faster the search will be.
            </p>
            <h4 className="mt-4">How does caching help?</h4>
            <p>
              The database has caching mechanisms built-in to help reduce the
              search time. So, if you perform a search and then perform the
              same search again, it will likely be faster. The caching extends
              to data that has recently been searched over in  the database,
              as opposed to searches that are identical.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    // return
    return (
      <>
        {
          updateEstimateTimer === null ?
            estimate ?
              <p>
                Estimate: ~{estimate} sec
              </p>
              : null
            : <p>
              Estimate: <FontAwesomeIcon icon={faLoader} spin className="ml-1" />
            </p>
        }
      </>
    );
  }
}


export default QueryEstimate;
