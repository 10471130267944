// Importing Components from node_modules
import React, { useEffect, useRef, useState } from 'react';

import * as jzip from 'jszip'; // Used to export the result's table information
import { Info } from '../components/Toasts';

import 'datatables.net-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';

import { SWARM_AURORA_TRANSLATIONS } from "../components/swarmaurora";
import { useDropdownSearchContext } from './context/stateManager';

// Used to set up the results table
const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');

// Used to export the result's table information
window.JSZip = jzip;

const columns = [
  {
    title: 'Start Time (UTC)',
    data: 'start',
    type: 'string',
    width: '75px',
  },
  {
    title: 'End Time (UTC)',
    data: 'end',
    type: 'string',
    width: '75px',
  },
  {
    title: 'Duration (min)',
    data: 'duration',
    type: 'num',
  },
  {
    title: 'Min (km)',
    data: 'min',
    type: 'num',
  },
  {
    title: 'Max (km)',
    data: 'max',
    type: 'num',
  },
  {
    title: 'Conjunction Type',
    data: 'type',
    type: 'string',
  },
  {
    title: 'Conjunction Between',
    data: 'between',
    type: 'string',
    width: '35%',
  },
  {
    title: 'Swarm-Aurora',
    render(data, type, full) {
      // set up the json parameter
      const jsonAurorax = {
        start: full.raw_start,
        end: full.raw_end,
        conjunction_type: full.type,
        instruments: [],
      };

      let tii_on = false;
      let epop_on = {
        "cer_on": false,
        "fai_on": false,
        "gap_on": false,
        "irm_on": false,
        "mgf_on": false,
        "nms_on": false,
        "rri_on": false,
        "sei_on": false,
      };

      // check if the space criteria blocks have any of the TII or ePOP metadata filters
      if (full.request.search_request
        && full.request.search_request.query
        && full.request.search_request.query.space
        && full.request.search_request.query.space.length > 0) {
        for (let spaceBlock of full.request.search_request.query.space) {
          if (spaceBlock.ephemeris_metadata_filters) {
            for (let expressionBlock of spaceBlock.ephemeris_metadata_filters.expressions) {
              // check for tii_on
              if (expressionBlock.key === "tii_on") {
                tii_on = true;
              } else if (Object.keys(epop_on).includes(expressionBlock.key)) {
                // check for cer_on, fai_on, gap_on, ... sei_on
                epop_on[expressionBlock.key] = true;
              }
            }
          }
        }
      }

      // set instruments
      for (let i = 0; i < full.sources.length; i++) {
        if (full.sources[i].source_type !== "event_list") {
          // set str and append -- skip "event_list" sources because they're not instruments that can be shown
          let instrumentString = `${full.sources[i].program}, ${full.sources[i].platform}, ${full.sources[i].instrument_type}`;
          jsonAurorax.instruments.push(instrumentString);

          // add extra instrument for Swarm TII when the metadata filter is on
          if (tii_on && instrumentString.includes('swarm') && instrumentString.includes('footprint')) {
            instrumentString = instrumentString.replace('footprint', 'thermal ion imager');
            jsonAurorax.instruments.push(instrumentString);
          }

          // check if any of the epop filters are on
          if (Object.keys(epop_on).some(instKey => epop_on[instKey])) {
            if (instrumentString === 'epop, epop, footprint') {
              for (let instKey of Object.keys(epop_on)) {
                // go through all the possible keys and add the instruments that are on
                if (epop_on[instKey]) {
                  jsonAurorax.instruments.push(`epop, epop, ${instKey.split("_")[0].toUpperCase()}`);
                }
              }
            }
          }
        }
      }

      // check to see if all instruments are in swarmaurora
      let has_all_instruments = true;
      for (let i = 0; i < jsonAurorax.instruments.length; i++) {
        if (Object.prototype.hasOwnProperty.call(SWARM_AURORA_TRANSLATIONS, jsonAurorax.instruments[i]) === false) {
          has_all_instruments = false;
          break;
        }
      }

      // set URL
      const href = `https://swarm-aurora.com/conjunctionFinder?json_aurorax=${encodeURIComponent(JSON.stringify(jsonAurorax))}`;

      // return html
      if (has_all_instruments === true) {
        return `<a href="${href}" class="btn btn-sm btn-outline-dark open-swarm" target="_blank">Open</a>`;
      } else {
        return `<a href="${href}" class="btn btn-sm btn-outline-dark disabled">Not yet available</a>`;
      }
    },
  },
  {
    title: 'Deep Links',
    data: 'deep_links',
    type: 'string',
  },
];

function buildDatatableColumnDefinitions(state) {
  return [
    { searchable: false, targets: [7] },
    { className: 'align-middle', targets: '_all' },
    { visible: state.ignoreDistances ? false : true, targets: [3, 4] },
  ];
}

function formatDate(dateString) {
  const indexOfT = dateString.indexOf('T');
  const datePart = dateString.substring(0, indexOfT);
  const timePart = dateString.substring(indexOfT + 1, dateString.length);
  const timeWithMins = timePart.substring(0, timePart.lastIndexOf(':'));
  const dateWithoutMins = `${datePart}  ${timeWithMins}`;
  return dateWithoutMins;
}

function duration(start, end) {
  const diffMillis = Math.abs(new Date(end) - new Date(start));
  const diffMin = (diffMillis / 1000 / 60) + 1;
  return diffMin;
}

// to calculate the criteria block name from the adhoc-lat-lon instrument_type
const latLonIndexes = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const transform = (row, requestInfo, index, deepLinksActive) => ({
  start: formatDate(row.start),
  end: formatDate(row.end),
  duration: duration(row.start, row.end),
  min: Number(row.min_distance).toFixed(0),
  max: Number(row.max_distance).toFixed(0),
  type: row.conjunction_type,
  between: row.data_sources.map((source) => {
    // convert the adhoc instrument_type return in the search results with the criteria block name
    if (source.display_name?.includes('adhoc')) {
      const adhocLetter = source.instrument_type.replace('adhoc-lat-lon-', '');
      if (requestInfo.query) {
        return `[${requestInfo.query.adhoc[latLonIndexes.indexOf(adhocLetter)].locations[0].lat}, ${requestInfo.query.adhoc[latLonIndexes.indexOf(adhocLetter)].locations[0].lon}]`;
      } else {
        return 'Custom ' + (latLonIndexes.indexOf(adhocLetter) + 1);
      }
    } else {
      return source.display_name;
    }
  }).join(' and '),
  deep_links: `<a id='datatable_index_${index}' class="btn btn-sm btn-outline-dark ${deepLinksActive ? "" : "disabled"}" onclick='document.getElementById("deepLinksIndex").innerHTML=${index}'>${deepLinksActive ? "Load <i class='fa-solid fa-upload' />" : "Enable first"}</a>`,
  raw_start: row.start,
  raw_end: row.end,
  sources: row.data_sources.map((source) => ({
    program: source.program,
    platform: source.platform,
    instrument_type: source.instrument_type,
    source_type: source.source_type,
  })),
  request: requestInfo,
});

function transformAll(deepLinksActive, searchResultData, requestInfo) {
  if (searchResultData) {
    return searchResultData.map((row, index) => transform(row, requestInfo, index, deepLinksActive));
  }
  return [];
}

function formatDataForExport(maxLength, exportData, tableHeaders, col) {
  let data = [];
  // Change how many spaces between each column you want
  let colSep = '    ';

  data.push(col.map(columnKey => {
    return tableHeaders[columnKey].padEnd(maxLength[columnKey], " ");
  }).join(colSep));

  let indexBasedMaxLengths = Object.values(maxLength);

  for (let row of exportData) {
    let rowData = [];
    for (let i = 0; i < row.length; i++) {
      rowData.push(row[i].padEnd(indexBasedMaxLengths[i], ' '));
    }

    data.push(rowData.join(colSep));
  }

  return data.join('\n');
}


function collectDataForExport(table, state) {
  let col = [];
  if (state.ignoreDistances) {
    col = ["start", "end", "duration", "type", "between"];
  } else {
    col = ["start", "end", "duration", "min", "max", "type", "between"];
  }

  let data = table.rows().data();
  let maxLength = {};
  let tableHeaders = {};

  let exportData = Array.from(data.map(row => {
    let rowData = [];
    for (let columnKey of col) {
      let value = row[columnKey].toString();

      maxLength[columnKey] = maxLength[columnKey] || -1;
      if (maxLength[columnKey] < value.length) {
        maxLength[columnKey] = value.length;
      }

      rowData.push(value);
    }

    return rowData;
  }));


  for (let columnKey of col) {
    let columnRef = columns.find(x => x.data === columnKey);

    if (!columnRef) { throw new Error("column not found or something"); }

    if (maxLength[columnKey] < columnRef.title.length) {
      maxLength[columnKey] = columnRef.title.length;
    }

    tableHeaders[columnKey] = columnRef.title;
  }

  return formatDataForExport(maxLength, exportData, tableHeaders, col);
}


export const changePage = (direction) => {
  if (direction !== "previous" && direction !== "next") {
    return;
  }

  let table = $("#conjunction-table").DataTable();

  table.page(direction).draw("page");
};

const buildAsciiExportButtonFunction = (state) => {
  return function (e, dt) {
    let body = collectDataForExport(dt, state);
    let blob = new Blob([body], { type: 'text/plain' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    // can't use queryID for some reason, so must go with this approach instead
    a.download = `conjunction_search_results.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };
};

const ResultsTable = ({ deepLinksActive }) => {
  const { state } = useDropdownSearchContext();
  const [dataTable, setDataTable] = useState();
  const [show, setShow] = useState();
  const ref = useRef(null);

  let thisQueryID = useRef();

  useEffect(() => {
    thisQueryID.current = state.queryID;
  }, [state.queryID]);

  const copyId = () => {
    navigator.clipboard.writeText(thisQueryID.current);
    Info(`Search request ID ${thisQueryID.current} has been copied to the clipboard.`);
  };

  /*
    give access to the SwarmAurora & DeepLinks functions from the rendered (uncontrolled)
    datatables rows
  */

  /*
    DOM Elements:
    l = # of entries
    f = search box
    i = total number of entries
    t = table
    p = pagination
    B = buttons
  */
  useEffect(() => {
    // set export filename
    const table = $(ref.current).DataTable({
      columns,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']],
      autoWidth: true,
      dom: "<'row'<'col-md-6'l><'col-md-6'f>>"
        + "<'row't>"
        + "<'row'<'col-md-6'i><'col-md-6'p>>"
        + "<'row'B>",
      processing: true,
      scrollCollapse: true,
      columnDefs: buildDatatableColumnDefinitions(state),
      search: {
        smart: false,
      },
      buttons: {
        dom: {
          button: { className: 'btn btn-outline-dark btn-sm' },
        },
        buttons: [
          {
            /* eslint-disable no-unused-vars */
            text: 'ASCII',
            action: buildAsciiExportButtonFunction(state),
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6], // exclude column for swarm-aurora "open"
            },
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          },
          {
            extend: 'pdf',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          },
          {
            extend: 'print',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          },
          {
            text: 'Copy to Clipboard',
            action: function (e, dt, node, config) {
              navigator.clipboard.writeText(collectDataForExport(dt));
              /* eslint-enable no-unused-vars */
            },
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          },
          {
            text: `Copy request ID`,
            action: () => copyId(),
          },
        ],
      },
    });

    window.test = table;

    // Add btn-group class to export
    table.buttons(0, null).containers().addClass('btn-group');
    setDataTable(table);

  }, [ref]);

  useEffect(() => {
    if (dataTable) {
      dataTable.clear();
      setShow('');
      if (state.request.data !== null
        && state.request.data.length > 0) {
        const data = transformAll(
          deepLinksActive,
          state.request.data,
          {
            ...state.request.search_request,
            ...state.request.logs,
            ...state.request.search_result,
            ...state.queryObject,
          },
        );
        dataTable.rows.add(data).draw();
      }
      dataTable.columns([3, 4]).visible(!state.ignoreDistances);
      dataTable.button(0).action(buildAsciiExportButtonFunction(state));
      dataTable.draw();
    } else {
      setShow('d-none');
    }
  }, [state.request.data]);

  // equiv componentWillUnmount
  useEffect(() => () => {
    $('.data-table-wrapper')
      .find('table')
      .DataTable()
      .destroy(true);
    setShow('');
  }, []);

  return (
    <>
      {' '}
      {
        <div className={show} style={{ margin: '5px 0 0' }}>
          <table ref={ref} id="conjunction-table" className="table table-sm table-hover conjunction-table" />
        </div>
      }
    </>
  );
};
ResultsTable.displayName = "ResultsTable";

export default ResultsTable;
