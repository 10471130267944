import { deployment_type } from "../deployment";

// set hosts and protocols
let host = "";
let http = "";
let ws = "";
if (deployment_type === "production") {
  // production
  host = "api.aurorax.space";
  http = "https";
  ws = "wss";
} else if (deployment_type === "staging") {
  // staging
  host = "api.staging.aurorax.space";
  http = "https";
  ws = "wss";
} else {
  // development or other
  host = "localhost:8080";
  http = "http";
  ws = "ws";
}
export { host, http, ws };

// set the base urls
const baseHttpUrl = `${http}://${host}/api/v1`;
const baseWsUrl = `${ws}://${host}/api/v1`;

// availability sources
export const ephemerisAvailabilitySourcesURL = `${baseHttpUrl}/availability/ephemeris`;
export const dataProductAvailabilitySourcesURL = `${baseHttpUrl}/availability/data_products`;

// data products synchronous search
export const dataProductSynchronousSearchURL = `${baseHttpUrl}/data_products/search_synchronous`;

// data sources
export const dataSourcesUrl = `${baseHttpUrl}/data_sources`;
export const dataSourcesSearchUrl = `${dataSourcesUrl}/search`;

// query describe
export const describeQueryUrl = `${baseHttpUrl}/utils/describe/query/conjunction`;

// conjunction search (note - the {request_id} is tacked on the end of this URL to
// subscribe to the websocket when the search button is clicked)
export const conjunctionSearchUrl = `${baseHttpUrl}/conjunctions/search`;
export const conjunctionSearchResponse = `${baseHttpUrl}/conjunctions/requests`;
export const conjunctionSearchResponseWs = `${baseWsUrl}/conjunctions/requests`;

// poll delay
export const pollDelayMs = 1000;

// Account requests
export const createAccountsUrl = `${baseHttpUrl}/accounts`;
export const authenticateAccountsUrl = `${baseHttpUrl}/authenticate`;
export const requestKeyUrl = `${baseHttpUrl}/accounts/request_api_key`;
export const endSessionUrl = `${baseHttpUrl}/authenticate/end_session`;
export const resetPasswordEmailUrl = `${baseHttpUrl}/accounts/request_password_reset_email`;
export const resetPasswordUrl = `${baseHttpUrl}/accounts/reset_password`;