import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from "@fortawesome/pro-regular-svg-icons";

import { dropdownSearchActions, useDropdownSearchContext } from './context/stateManager';

export default function ResetCriteria({ type, index }) {
  const { dispatch } = useDropdownSearchContext();

  function resetCriteriaBlock() {
    dispatch({
      type: dropdownSearchActions.RESET_CRITERIA_BLOCK,
      payload: {
        type: type,
        index: index,
      },
    });
  }

  return (
    <Button
      className="reset-critieria-row"
      variant="light"
      size="sm"
      onClick={resetCriteriaBlock}
      title="Reset criteria block"
    >
      <FontAwesomeIcon icon={faUndo} fixedWidth />
    </Button>
  );
}
