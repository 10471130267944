import {
  deployment_type as deploymentType,
} from '../../../deployment';

// double conjunction: a few THEMIS ASIs and Swarm
// ---------------------------------------------------
let example1 = {
  start: '2019-01-01T00:00:00.000Z',
  end: '2019-01-03T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['themis-asi'],
    platforms: ['fort smith', 'gillam'],
    instrument_types: [],
    ephemeris_metadata_filters: {},
  }],
  space: [{
    programs: ['swarm'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  events: [],
  max_distances: {
    'ground1-space1': 500,
  },
};

// double conjunction: a couple REGOs and a few satellites
// -----------------------------------------------------------
let example2 = {
  start: '2019-02-01T00:00:00.000Z',
  end: '2019-02-02T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['rego'],
    platforms: ['gillam', 'lucky lake'],
    instrument_types: ['redline ASI'],
    ephemeris_metadata_filters: {},
  }],
  space: [{
    programs: ['dmsp', 'swarm', 'themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  events: [],
  max_distances: {
    'ground1-space1': 600,
  },
};

// double conjunction: TREx and a few satellites
// -------------------------------------------------
let example3 = {
  start: '2019-03-01T00:00:00.000Z',
  end: '2019-03-05T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['trex'],
    platforms: [],
    instrument_types: [],
    ephemeris_metadata_filters: {},
  }],
  space: [{
    programs: ['cluster', 'dmsp', 'mms', 'swarm', 'themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  events: [],
  max_distances: {
    'ground1-space1': 500,
  },
};

// double conjunction: several instrument types and a bunch of satellites, close distance
// ------------------------------------------------------------------------------------------
let example4 = {
  start: '2019-01-01T00:00:00.000Z',
  end: '2019-01-05T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: [],
    platforms: [],
    instrument_types: ['blueline ASI', 'near-infrared ASI', 'panchromatic ASI', 'redline ASI', 'RGB ASI', 'spectrograph'],
    ephemeris_metadata_filters: {},
  }],
  space: [{
    programs: ['arase', 'cluster', 'dmsp', 'epop', 'mms', 'rbsp', 'small-explorer', 'swarm', 'themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  events: [],
  max_distances: {
    'ground1-space1': 200,
  },
};

// triple conjunction: all THEMIS ASIs and Swarm, large distance
// ----------------------------------------------------------------
let example5 = {
  start: '2019-01-01T00:00:00.000Z',
  end: '2019-01-09T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['themis-asi'],
    platforms: [],
    instrument_types: [],
    ephemeris_metadata_filters: {},
  }],
  space: [{
    programs: ['themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  },
  {
    programs: ['swarm'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  },
  ],
  events: [],
  max_distances: {
    'ground1-space1': 900,
    'ground1-space2': 900,
    'space1-space2': null,
  },
};

// quadruple conjunction: TREx, REGO, Swarm, and THEMIS
// -------------------------------------------------------
let example6 = {
  start: '2020-01-01T00:00:00.000Z',
  end: '2020-01-04T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['trex'],
    platforms: [],
    instrument_types: [],
    ephemeris_metadata_filters: {},
  },
  {
    programs: ['rego'],
    platforms: [],
    instrument_types: [],
    ephemeris_metadata_filters: {},
  },
  ],
  space: [{
    programs: ['swarm'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  },
  {
    programs: ['themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  },
  ],
  events: [],
  max_distances: {
    'ground1-ground2': 0,
    'ground1-space1': 500,
    'ground1-space2': 500,
    'ground2-space1': 500,
    'ground2-space2': 500,
    'space1-space2': null,
  },
};

// double conjunction: THEMIS ASI and Swarm when
// machine learning model says there's APA
// -------------------------------------------------
let example7 = {
  start: '2008-01-01T00:00:00.000Z',
  end: '2008-01-31T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  ground: [{
    programs: ['themis-asi'],
    platforms: [],
    instrument_types: [],
    ephemeris_metadata_filters: {
      logical_operator: 'AND',
      expressions: [{
        key: 'calgary_apa_ml_v1',
        operator: 'in',
        values: [
          'classified as APA',
        ],
      },
      {
        key: "calgary_apa_ml_v1_confidence",
        operator: ">=",
        values: [
          "95",
        ],
      },
      ],
    },
  }],
  space: [{
    programs: ['themis'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  events: [],
  max_distances: {
    'ground1-space1': 500,
  },
};

// double conjunction: swarm spacecrafts and a custom location
// --------------------------------------------------------------
let example8 = {
  start: '2021-01-01T00:00:00.000Z',
  end: '2021-01-03T23:59:59.000Z',
  conjunction_types: ['nbtrace'],
  space: [{
    programs: ['swarm'],
    platforms: [],
    instrument_types: ['footprint'],
    ephemeris_metadata_filters: {},
    hemisphere: ['northern'],
  }],
  adhoc: [
    {
      locations: [
        { lat: 51.05, lon: -114.07 },
      ],
    }],
  max_distances: {
    'space1-adhoc1': 400,
  },
};


if (deploymentType === 'staging' || deploymentType === 'development') {
  example1.epoch_search_precision = 60;
  example2.epoch_search_precision = 60;
  example3.epoch_search_precision = 60;
  example4.epoch_search_precision = 60;
  example5.epoch_search_precision = 60;
  example6.epoch_search_precision = 60;
  example7.epoch_search_precision = 60;
  example8.epoch_search_precision = 60;
}

// export for use elsewhere
export {
  example1,
  example2,
  example3,
  example4,
  example5,
  example6,
  example7,
  example8,
};
