// Importing Components from node_modules
import React, { useEffect, useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker"; // this has a jquery dependency, but it's the best daterange/timerange picker out there...

// Importing Styling
import "bootstrap-daterangepicker/daterangepicker.css"; // Without this a daterangepicker appears under the footer

// Importing Project-defined Components
import { dropdownSearchActions, useDropdownSearchContext } from "./context/stateManager";

const DateRange = () => {
  const { state, dispatch } = useDropdownSearchContext();
  const ref = useRef(null);

  const getMaxDate = () => {
    // ---
    // note this was changed to a static end date to facilitate
    // future mission planning availabile data. Previous code
    // left commented out in case we need it again later.
    // ---
    const maxDateLimit = new Date('2029-12-31 23:59:00');
    // const maxDateLimit = new Date();
    // maxDateLimit.setMonth(maxDateLimit.getMonth() + 3);
    // maxDateLimit.setHours(23);
    // maxDateLimit.setMinutes(59);
    return maxDateLimit;
  };

  useEffect(() => {
    ref.current.setStartDate(state.queryObject.start);
    ref.current.setEndDate(state.queryObject.end);
  }, [state.queryObject.start, state.queryObject.end, ref]);

  const handleApply = (event, picker) => {
    if (event.type !== "cancel") {
      let start = "";
      let end = "";
      if (!picker.startDate.isUTC()) {
        start = `${picker.startDate.format("YYYY-MM-DDTHH:mm:ss.SSS")}Z`;
        end = `${picker.endDate.format("YYYY-MM-DDTHH:mm:ss.SSS")}Z`;
      } else {
        start = picker.startDate.toISOString();
        end = picker.endDate.toISOString();
      }
      dispatch({
        type: dropdownSearchActions.SET_DATETIME,
        payload: {
          start: start,
          end: end,
        },
      });
    }
  };

  return (
    <>
      <label
        htmlFor="dateRangePicker"
        className="conjunction-label-text"
      >
        <p style={{ textDecoration: "underline" }}>
          Date Range (UTC)
        </p>
        <DateRangePicker
          ref={ref}
          onHide={handleApply} // onEvent as causing issues with stale state
          initialSettings={{
            autoApply: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: true,
            locale: {
              format: "yyyy/MM/DD HH:mm",
            },
            maxSpan: {
              years: 10,
            },
            minDate: "1950-01-01T00:00:00.000Z",
            maxDate: getMaxDate(),
          }}
        >
          <input
            type="text"
            id="dateRangePicker"
            className="form-control form-control-sm"
            style={{ width: "270px" }}
          />
        </DateRangePicker>
      </label>
    </>
  );
};

// Required as the use of forwardRef means there is no display name
DateRange.displayName = "DateRange";
export default DateRange;
