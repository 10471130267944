import React, { useRef } from "react";
import { SelectPicker } from "rsuite";

import { Warn } from "../components/Toasts";
import { dropdownSearchActions, useDropdownSearchContext } from "./context/stateManager";
import { sumTotalNumberOfCriterialBlocks } from "./CriteriaBlocksTable";

export default function AddSpaceGroundEvent() {
  const picker = useRef();
  const { state, dispatch } = useDropdownSearchContext();

  const options = [
    { label: "Add Ground", value: "ground" },
    { label: "Add Space", value: "space" },
    { label: "Add Event", value: "event" },
    { label: "Add Custom Location", value: "adhoc" },
  ];

  function onChange(value) {
    if (value === "ground") {
      addGround();
    } else if (value === "space") {
      addSpace();
    } else if (value === "event") {
      addEvent();
    } else if (value === "adhoc") {
      addAdhoc();
    }
  }

  function isTooManyCriteriaBlocks(state) {
    const totalBlocks = sumTotalNumberOfCriterialBlocks(state) + 1;
    return totalBlocks > 10;
  }

  function addGround() {
    if (isTooManyCriteriaBlocks(state)) {
      Warn("Maximum of 10 criteria blocks reached, not adding another");
    }
    dispatch({
      type: dropdownSearchActions.ADD_CRITERIA_BLOCK,
      payload: {
        type: "ground",
      },
    });

  }

  function addSpace() {
    if (isTooManyCriteriaBlocks(state)) {
      Warn("Maximum of 10 criteria blocks reached, not adding another");
    }
    dispatch({
      type: dropdownSearchActions.ADD_CRITERIA_BLOCK,
      payload: {
        type: "space",
      },
    });
  }

  function addEvent() {
    if (isTooManyCriteriaBlocks(state)) {
      Warn("Maximum of 10 criteria blocks reached, not adding another");
    }
    dispatch({
      type: dropdownSearchActions.ADD_CRITERIA_BLOCK,
      payload: {
        type: "event",
      },
    });
  }

  function addAdhoc() {
    if (isTooManyCriteriaBlocks(state)) {
      Warn("Maximum of 10 criteria blocks reached, not adding another");
    }
    if (state.ignoreDistances) {
      Warn("Beware! Performing searches with a custom location and distances disabled will return indeterminate results");
    }
    dispatch({
      type: dropdownSearchActions.ADD_CRITERIA_BLOCK,
      payload: {
        type: "adhoc",
      },
    });

  }

  return (
    <>
      <SelectPicker
        id="data-type"
        ref={picker}
        data={options}
        value={""}
        placeholder="Add Criteria Block"
        onChange={(value) => onChange(value)}
        style={{ margin: "-3px 0 0 0", width: "175px" }}
        searchable={false}
        cleanable={false}
      />
    </>
  );
}
